import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import ComplaintColumns from "../../assets/columns/ComplaintsColumns";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import "../projects/Projects.scss";
import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import { ORDER_DIRECTION } from "../../Constants";
import ToolTip from "../../components/tooltip/Tooltip";
import DeleteRow from "../DeleteRow";
import SearchList from "../../components/search-list/SearchList";
import ListHeader from "../../components/list-header/ListHeader";
import usePageMeta from "../../hooks/usePageMeta";
import Searchbar from "../../serachbar/Searchbar";
import FilterAccordian from "../../components/accordian/FilterAccordian";
import { ComplaintFilterFields } from "../../assets/fields/ComplaintFilterFields";
import { useState } from "react";
import useUserRole from "../../hooks/useUserRole";
import { FaArrowCircleLeft } from "react-icons/fa";

const Complaints = () => {
  const { isAdmin } = useUserRole();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setMeta } = usePageMeta();
  const columns = [
    ...ComplaintColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link to={`/complaints/${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          {isAdmin && (
            <>
              <ToolTip label="Edit">
                <Link to={`update/${row?.id}`}>
                  <Icon.PencilSquare className="action-icons" />
                </Link>
              </ToolTip>

              <ToolTip label="Delete">
                <DeleteRow row={row} url="/Complaint" />
              </ToolTip>
            </>
          )}
        </>
      ),
    },
  ];
  const { loading, data, creator, created } = useSelector(
    (state) => state.records
  );
  const { items = [] } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    getComplaints();
  }, [creator, created]);

  const getComplaints = () => {
    const beneficiariesListUrl = id
      ? `/Complaint/GetAll?ProjectId=${id}`
      : "/Complaint/GetAll";

    dispatch({
      type: "records/getRecords",
      payload: {
        url: beneficiariesListUrl,
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
      },
    });
    setMeta({ title: "Complaints" });
  };

  const [searchFilterIsOpen, setSearchFilterIsOpen] = useState(false);

  let icons = [];

  isAdmin &&
    icons.push({
      id: 1,
      name: "PlusCircle",
      url: "/complaints/add",
      itemClassName: "btn btn-success d-flex align-items-center justify-content-center",
                    iconColor: "#fff",
                    label: "Add Complaint",
                    text: "Add Complaint",
    });

  return (
    <>
      <Searchbar
        className="mb-3"
        searchListParams={{
          url: "/Complaint/GetAll",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
          searchFieldName: "ComplainantName",
        }}
        listHeaderParams={{
          options: icons,
        }}
        listheaderChildren={
          <ToolTip label="Filter">
            {/* <Icon.Funnel
              className="text-primary cursor-pointer mr-2"
              onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
            /> */}
            <button
                className="btn btn-primary ml-auto d-flex mb-2 align-items-center mr-2"
                onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
              >
                <Icon.Funnel
                  size={20}

                />
                <span className="ml-2">Filter</span>
              </button>
          </ToolTip>
        }
      />

      <FilterAccordian
        formFields={ComplaintFilterFields}
        toggleFilter={searchFilterIsOpen}
        url="/Complaint/GetAll"
      />
      {/* Table SEction */}
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px", padding:"5px" }}>Go Back</span></button>
   
      <div className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} data={items && items}  canExport={true} />
      </div>
    </>
  );
};

export default Complaints;
