import { put } from "redux-saga/effects";
import { toast } from 'react-toastify';

import { saveUserState } from "../../utils/localStorage";
import { onRecordSuccess, setUser } from "../reducers";
import { getError } from "../../utils/util";

export function* branchedActionAsync({ payload = {} }) {
    const notify = ({ type = 'success', message = 'Successfull!', position = toast.POSITION.TOP_RIGHT } = {}) => {
        toast[type](message, {
            position: position
        });
    };
    const { response = {}, updated = false, created = false, code = '', message = '' } = payload;
    const isLoginApi = payload.payload?.url === '/Account/Login';
    if ((updated || created) && !isLoginApi) {
        yield put({ type: onRecordSuccess.type });
        notify({ message });
    }
    if (isLoginApi) {
        saveUserState(response?.data)
        yield put({ type: setUser.type, payload: response?.data })
        yield put({ type: 'records/setRecords', payload: { created: true } })
    }
    if (code === 'ERR_BAD_REQUEST' || code === 'TypeError') {

        let errorMessage = message ? message : "Something went wrong, please try again"
        if (response?.data?.status > 204 && response?.data?.errors) {
            errorMessage = getError(response?.data?.errors)
        }
        if (response?.status === 401) {
            errorMessage = 'Session was expired, please login again';
            saveUserState({})
            window.location = '/login'
        }

        notify({
            message: errorMessage,
            type: 'error',
        });
    }
}

export function* formatRecordsAsync({ payload = {} }) {
    let newData = {}
    Object.entries(payload).forEach(([key, item]) => {
        //for dropdowns
        if (Array.isArray(item)) {
            newData = {
                ...newData, [key]: {
                    name: key, value: item.map((innerItem) => {
                        if (innerItem?.id && innerItem?.name)
                            return { value: innerItem?.id, label: innerItem?.name }
                        else return { ...innerItem }
                    })
                }
            }
        }
        else newData = { ...newData, [key]: { name: key, value: item } };
    })
    yield put({ type: "form/setFormValues", payload: newData })
}