import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setPageMeta } from '../redux/reducers';

const usePageMeta = ({ title = '', breadcrumbs = '' } = {}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [state, setState] = useState({})

    const setMeta = (params = null) => {
        if (params) {
            dispatch({ type: setPageMeta.type, payload: { ...params } })
            setState(params);
        }
    }

    function getMeta() {
        if (title) return { title, breadcrumbs }

        switch (location?.pathname) {
            case '/projects':
                return { title: 'All Projects', breadcrumbs: '' }
            case '/dashboard':
                return { title: 'Dashboard', breadcrumbs: '' }
            default:
                return { title: 'SIF', breadcrumbs: '' }
        }
    }

    useEffect(() => {
        const metaValue = getMeta();
        setPageMeta(metaValue)
        return () => {
            setPageMeta({})
        };
    }, [location]);

    return { setMeta, ...state };
}
export default usePageMeta;