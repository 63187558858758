import React from 'react';
import Select from 'react-select';

export default function Dropdown({ options, ...otherProps }) {
  function getSelectedValue(val = '') {
    return options.find(({ value }) => value == val)
  }
  return (
    <Select
      className="dropdown-input"
      options={options}
      {...otherProps}
      value={otherProps?.isMulti && getSelectedValue(otherProps?.value)}
    />
  );
}