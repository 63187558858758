export const achievementFields = [
  {
    "label": "Numbers Achieved",
    "placeholder": "Enter Numbers",
    "type": "number",
    "inForm": true,
    "name": "NumbersAchieved",
    "wrapperClass": "col-6"
  },
  {
    "label": "Date",
    "inputType": "date",
    "inForm": true,
    "name": "Date",
    "wrapperClass": "col-6"
  },
  {
    "label": "Attachments",
    "inputType": "file",
    "inForm": true,
    "name": "Attachments",
    "wrapperClass": "col-6"
  },
  {
    "label": "Comments",
    "placeholder": "Enter Comments",
    "inputType": "textarea",
    "inForm": true,
    "name": "Comments",
    "wrapperClass": "col-6"
  },
  {
    "inputType": "button",
    "buttonType": "submit",
    "children": "Submit",
    "inForm": true,
    "wrapperClass": "mr-3 btn-wrapper position-right"
  }
]