import FindValue from '../components/crud/FindValue'
import AddEnter from '../components/crud/AddEnter'
// import Approve from '../components/crud/Approve'

export const tabsList = [
  { id: 1, title: 'Entries' },
  { id: 2, title: 'Add Entry' },
  // { id: 3, title: 'Approve' },
]

// export const tabData = [<FindValue />, <AddEnter />, <Approve />]
export const tabData = [<FindValue />, <AddEnter />]