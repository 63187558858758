import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Activities from "../../components/activities/Activities";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import SmartIndicator from "../../components/smart-indicator/SmartIndicator";
import {
  createRecord,
  putRecord,
  setStepper,
} from "../../redux/reducers";
import "./Projects.scss";
import AddSpecificObjective from "../../components/specific-objective/AddSpecificObjective";
import ProjectDetailForm from "../../components/project/ProjectDetailForm";
import AddExpectedResult from "../../components/smart-indicator/AddExpectedResult";
import { INTERVENTION_LOGIC_TYPE, PROJECT_STATUS } from "../../Constants";
import AddActivity from "../../components/activities/AddActivity";
import ProjectFunding from "../../components/project-funding/ProjectFunding";
import { FaArrowCircleLeft } from "react-icons/fa";

const AddUpdateProject = () => {
  let { id } = useParams();
  let canUpdate = false;
  if (id) canUpdate = true;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formValues = useSelector((state) => state.form.values);
  const { stepper } = useSelector((state) => state.setting);
  const recordsData = useSelector((state) => state.records?.data);
  const { status } = recordsData;
  const { currentStep } = stepper;

  const onAddProject = () => {
    dispatch({
      type: createRecord.type,
      payload: { ...formValues, url: "/Project" },
    });
  };

  const onUpdateProject = () => {
    // console.log("🚀 ~ file: AddUpdateProject.js:46 ~ onUpdateProject ~ currentStep:", currentStep, status)
    if (currentStep <= 1 && status !== PROJECT_STATUS.COMPLETED && recordsData?.id) {
      updateProjectRecord();
    }
    else
      dispatch({
        type: setStepper.type,
        payload: { currentStep: currentStep + 1 },
      });
  };

  //get form fields
  useEffect(() => {
    dispatch({ type: "fields/fetchLayout", payload: "/json/project-fields" });
  }, []);

  function submitForm() {
    // updateProjectRecord();
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: "/Project/ProjectFundingDetails" },
    });

    navigate("/projects")
  }

  function updateProjectRecord() {
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: "/Project" },
    });
  }

  return (
    <div className="mb-4">
      <hr />
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px" }}>Go Back</span></button>

      <FormStepper
        onNext={() => (canUpdate ? onUpdateProject() : onAddProject())}
        initialStep={0}
        onSubmit={() => submitForm()}
      >
        <Step key="projectDetailStep" title="Project Details">
          <ProjectDetailForm canUpdate={canUpdate} projectId={id} />
        </Step>
        <Step key="interventionLogicStep" title="Specific Objective">
          <div className="col-12">
            <AddSpecificObjective projectId={id} />
            <hr />
            <SmartIndicator
              projectId={id}
              type={INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE}
            />
          </div>
        </Step>
        <Step key="logFramStep" title="Specific Objective Results">
          <AddExpectedResult projectId={id} />
          <SmartIndicator
            projectId={id}
            type={INTERVENTION_LOGIC_TYPE.EXPECTED_RESULT}
          />
        </Step>
        <Step key="step3" title="Activities & Tasks">
          <AddActivity projectId={id} />
          <Activities projectId={id} />
        </Step>
        <Step key="step4" title="Funding Details">
          <ProjectFunding projectId={id} />
        </Step>
      </FormStepper>
    </div>
  );
};
export default AddUpdateProject;
