import { loadUserState } from "./localStorage";

export const isUserLoggedIn = () => {
  let userState = loadUserState();
  if (userState?.token) return true;
  return false;
};

export const getAuthToken = () => {
  let userState = loadUserState();
  return userState?.token;
};

export const getFieldsByCategory = (fields = [], key) => {
  return fields.filter(({ category = "" }) => category === key);
};

export const getError = (message) => {
  try {
    const messageJson =
      typeof message === "string" ? JSON.parse(message) : message;
    let error = "";
    if (typeof messageJson === "object") {
      Object.keys(messageJson).forEach((i) => {
        messageJson[i].forEach((j) => {
          error += j;
        });
      });
    } else if (typeof messageJson === "string") {
      error = message;
    } else error = "Something went wrong, please try again!";
    return error;
  } catch (error) {
    return "Something went wrong, please try again";
  }
};

export const getIntProgressValue = (value) => {
  if (!isNaN(value)) return value;
  else if (typeof value === "string")
    return parseInt(value.replace(/[`%{\}[\]/]/gi, ""));
  // return parseInt(value.replace(/[`%\{\}\[\]\\\/]/gi, ""));
  else return 0;
};

export const getPieFormatedData = (data) => {
  let labels = [],
    values = [];
  if (!data) return { labels, values };
  Object.entries(data)
    .filter(([key, value]) => key !== "total")
    .forEach(([key, value]) => {
      labels = [...labels, key.toUpperCase()];
      values = [...values, value];
    });
  return { labels, values };
};

export const getBarChartFormattedDate = (data = []) => {
  return data.map(({ name, count }) => ({ x: name, y: count }));
};

export const getPieFormatedDataByArray = (data) => {
  let labels = [],
    values = [];
  if (!data) return { labels, values };

  data.forEach((d) => {
    let label =
      typeof Object.values(d)[0] === "number"
        ? Object.values(d)[0]
        : Object.values(d)[0].toUpperCase();
    if (label && label.length > 10) {
      label = truncateText(label, 10);
    }
    labels = [...labels, label];
    values = [...values, getIntProgressValue(Object.values(d)[1])];
  });
  return { labels, values };
};

export const canView = (allowedRoles, CurrentMemberRoles) => {
  if (typeof CurrentMemberRoles === "string")
    CurrentMemberRoles = [CurrentMemberRoles];

  let result = false;
  allowedRoles.forEach((role) => {
    if (
      CurrentMemberRoles &&
      (CurrentMemberRoles.some((res) => res.includes(role)) ||
        CurrentMemberRoles.includes(role))
    ) {
      result = true;
    }
  });

  return result;
};

export const getCurrentUserRole = (user) => user?.userDetail?.role;

export const truncateText = (lbl, maxNumber) => {
  if (lbl.length > maxNumber) return lbl.substring(0, maxNumber) + "...";
  return lbl;
};

export const formateDateTime = (date) => {
  return date.replace(/T.*/, "").split("-").join("-");
};


export const countComplaintsByStatus = (complaints) => {
  let counts = {
    Pending: 0,
    Ongoing: 0,
    Closed: 0,
    Cancelled: 0
  };

  const statusMap = {
    Pending: 'Pending',
    Ongoing: 'Ongoing',
    Closed: 'Closed',
    Cancelled: 'Cancelled'
  };

  complaints?.data?.items?.forEach((item) => {
    const status = item?.statusOfComplaint;
    if (statusMap[status]) {
      counts[statusMap[status]]++;
    }
  });

  return counts;
};