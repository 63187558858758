import React from "react";
import { subActivityCompleteFields } from "../../assets/fields/subActivityCompleteFields";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import Form from "../form/Form";
import Loader from "../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { putRecord } from "../../redux/reducers";

const SubActivityCompleteModal = ({
  modalIsOpen,
  selectedSubActvity,
  toggleSubActivityAchievementModal,
}) => {
  const { loading, created } = useSelector((state) => state.records);
  const dispatch = useDispatch();

  const postRecord = (params) => {
    const newParams = { ...params, id: { value: selectedSubActvity.id } };
    dispatch({
      type: putRecord.type,
      payload: {
        url: `/SubActivity/api/SubActivity/Submit`,
        ...newParams,
      },
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      ariaHideApp={false}
      overlayClassName="modal-sm smart-indicator-modal"
    >
      <Icon.XCircle
        onClick={toggleSubActivityAchievementModal}
        className="action-icons"
      />
      <h4>{selectedSubActvity?.name}</h4>
      <Loader loading={loading} />
      <Form
        formData={subActivityCompleteFields}
        // onSubmit={(params) => postData(params)}
        onSubmit={(params) => postRecord(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
    </Modal>
  );
};

export default SubActivityCompleteModal;
