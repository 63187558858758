import React, { useeffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import { subActivityFields } from "../../assets/fields/subActivityFields";
import { createRecord, putRecord } from "../../redux/reducers";
import Form from "../form/Form";
import "./Activities.scss";
import Loader from "../../components/loader/loader";

const SubActivityModal = ({
  modalIsOpen,
  toggleSubActivityModal,
  projectId,
  isUpdate = false,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.records);

  function postData(params) {
    if (isUpdate) {
      dispatch({
        type: putRecord.type,
        payload: { ...params, url: "/SubActivity" },
      });
    } else {
      dispatch({
        type: createRecord.type,
        payload: { ...params, url: "/SubActivity" },
      });
    }
  }

  function formatFields() {
    let fields = subActivityFields;
    let _t = fields.map((field) => {
      if (field.name == "activity" || field.name == "activityId")
        return { ...field, url: `${field.url}?projectId=${projectId}` };
      return field;
    });
    return _t;
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Smart Indicator"
      >
        <Icon.XCircle
          onClick={toggleSubActivityModal}
          className="action-icons"
        />
        <h4>Sub Activities</h4>
        <Loader loading={loading} />
        <Form
          formData={formatFields()}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </>
  );
};

export default SubActivityModal;
