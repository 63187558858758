import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import UserManagementColumns from "../../assets/columns/UserManagementColumns";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import { useEffect } from "react";
import "../projects/Projects.scss";
import Loader from "../../components/loader/loader";
import { ORDER_DIRECTION } from "../../Constants";
import ToolTip from "../../components/tooltip/Tooltip";
import DeleteRow from "../DeleteRow";
import usePageMeta from "../../hooks/usePageMeta";
import Searchbar from "../../serachbar/Searchbar";
import { createRecord, deleteRecord } from "../../redux/reducers/recordSlice";

const UserManagements = () => {
  const { setMeta } = usePageMeta();
  const { loading, data, creator, created } = useSelector(
    (state) => state.records
  );
  const { items = [] } = data;
  const dispatch = useDispatch();

  const handleApprove = (url) => {
    dispatch({
      type: createRecord.type,
      payload: { url },
    });
  };
  const columns = [
    ...UserManagementColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link to={`${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          <ToolTip label="Delete">
            <DeleteRow row={row} url="/UserManagement" />
          </ToolTip>
          {!row.isApproved && (
            <ToolTip label="Approve">
              <Icon.CheckLg
                className="cursor-pointer text-success"
                onClick={() =>
                  handleApprove(`/Account/ApproveUser?id=${row?.id}`)
                }
              />
            </ToolTip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getUserManagement();
  }, [creator, created]);

  const getUserManagement = () => {
    dispatch({
      type: "records/getRecords",
      payload: {
        url: "/UserManagement/GetAll",
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
      },
    });
    setMeta({ title: "User Management" });
  };

  return (
    <>
      <Searchbar
        className="mb-3"
        searchListParams={{
          url: "/UserManagement/GetAll",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
          searchFieldName: "search.value",
        }}
        listHeaderParams={{
          options: [{
             id: 1,
              name: "PlusCircle", 
              url: "/UserManagement/add",
              itemClassName: "btn btn-success d-flex align-items-center justify-content-center",
                    iconColor: "#fff",
                    label: "Add User",
                    text: "Add User",
           }],
        }}
      />

      <div className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} data={items && items}  canExport={true} />
      </div>
    </>
  );
};

export default UserManagements;
