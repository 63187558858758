import React from "react";
import "./Complaint.scss";
import RecieveMode from "./RecieveMode";
import TypeOfComplaint from "./TypeOfComplaint";
import NatureOfComplaint from "./NatureOfComplaint";
import StatusOfTreatment from "./StatusOfTreatment";
import Activities from "./Activities";
import Gender from "./Gender";
import Age from "./Age";
import ProjectsListForComplaint from "./ProjectsListForComplaint";

const MainComplaintTab = () => {
  return (
    <div class="container-fluid p-0">
      <div class="row px-0 g-3 row-container mt-4">
        <div class="col-xl-3 margin">
          <RecieveMode />
        </div>
        <div class="col-xl-3 margin">
          <TypeOfComplaint />
        </div>
        <div class="col-xl-3 margin">
          <NatureOfComplaint />
        </div>
        <div class="col-xl-3">
          <StatusOfTreatment />
        </div>
      </div>
      <div class="row px-0 g-3 row-container mt-4">
        <div class="col-xl-7 margin">
          <div class="row px-0">
            <div class="col-xl-12">
              <Activities />
            </div>
          </div>
          <div class="row px-0 g-3 row-container mt-4">
            <div className="col-xl-6 margin">
              <Gender />
            </div>
            <div className="col-xl-6">
              <Age />
            </div>
          </div>
        </div>
        <div class="col-xl-5">
          <ProjectsListForComplaint />
        </div>
      </div>
    </div>
  );
};

export default MainComplaintTab;
