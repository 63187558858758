import React, { useEffect, useState } from "react";
import { RecordCircle } from "react-bootstrap-icons";
import "./Indicators.scss";
import Chart from "react-apexcharts";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import { truncateText } from "../../../utils/util";

// const Indicator = () => {
//   const dispatch = useDispatch();

//   const [selectedProjectID, setSelectedProjectID] = useState(null);
//   const {indicatorsData =[]  } = useSelector((state) => state.records?.current);
//   console.log("🚀 ~ Indicator ~ indicatorsData:", indicatorsData)


//   useEffect(() => {
//     setTimeout(() => {
//       getIndicatorsData();
//     }, 200)
//   }, [])

//   // useEffect(() => {
//   //   if (selectedProjectID) {
//   //     dispatch({
//   //       type: getRecords.type,
//   //       payload: {
//   //         url: `/Dashboard/GetActivityVsTarget?Project.Id=${selectedProjectID}&DisablePagination=false`,
//   //         appendCurrentReducerKey: "indicatorsData",
//   //       },
//   //     });
//   //   }
//   // }, [selectedProjectID])


//   const getIndicatorsData = () => {
//     dispatch({
//       type: getRecords.type,
//       payload: {
//         url: `/Dashboard/GetSmartIndicatorPerformance`,
//         appendCurrentReducerKey: "indicatorsData",
//       },
//     });
//   }
//   // var options = {
//   //   chart: {
//   //     height: 350,
//   //     type: "line",
//   //     stacked: false,
//   //     toolbar: {
//   //       tools: {
//   //         download: false,
//   //       },
//   //     },
//   //   },
//   //   dataLabels: {
//   //     enabled: false,
//   //   },
//   //   colors: ["#CF5300", "#0E7BB6","#1DDBA9"],
//   //   stroke: {
//   //     width: [4, 4],
//   //   },
//   //   plotOptions: {
//   //     bar: {
//   //       columnWidth: "20%",
//   //     },
//   //   },
//   //   xaxis: {
//   //     show: false,
//   //     labels: {
//   //       show: false
//   //     },
//   //   },
//   //   yaxis: [
//   //     {
//   //       axisTicks: {
//   //         show: true,
//   //       },
//   //       axisBorder: {
//   //         show: true,
//   //         color: "#ccc",
//   //       },
//   //       labels: {
//   //         style: {
//   //           colors: "#ccc",
//   //         },
//   //       },
//   //     },
//   //   ],
//   //   tooltip: {
//   //     shared: false,
//   //     intersect: true,
//   //     x: {
//   //       show: false,
//   //     },
//   //   },
//   //   legend: {
//   //     show: false,
//   //   },
//   //   grid: {
//   //     show: false,
//   //   },
//   // };

//   // var series = [
//   //   {
//   //     name: "Series A",
//   //     data: [20, 29, 37, 36, 44, 45, 50, 58],
//   //   },
//   //   {
//   //     name: "Series B",
//   //     data: [30, 39, 47, 46, 54, 55, 60, 68],
//   //   },
//   //   {
//   //     name: "Series C",
//   //     data: [40, 49, 57, 56, 64, 65, 70, 78],
//   //   },
//   // ];

//   return (
//     <div className="bg-white shadow-sm indicator-map-container h-100">
//       <div className="d-flex justify-content-between w-100">
//         <Label>Indicators Performance</Label>

//         <div className="d-flex align-items-center">
//           <div className="mx-1">
//             <RecordCircle color="orange" />
//             <text className={`mx-1 orange-dot`}>Indicator 1</text>
//           </div>
//           <div className="mx-1">
//             <RecordCircle color="skyblue" />
//             <text className="mx-1 sky-blue">Indicator 2</text>
//           </div>
//           <div className="mx-1">
//             <RecordCircle color="green" />
//             <text className="mx-1 green-dot">Indicator 3</text>
//           </div>
//         </div>
//       </div>

//       {/* <Chart
//         series={series}
//         options={options}
//         type="line"
//         height={"90%"}
//         className="w-100 mt-4"
//       /> */}
//     </div>
//   );
// };

const Indicator = () => {

  const dispatch = useDispatch();

  const { inidicatorsData ={}} = useSelector((state) => state.records?.current );

  useEffect(() => {
      setTimeout(() => {
          getIndicatorsData();
      }, 200);
  }, []);

  const getIndicatorsData = () => {
      dispatch({
          type: getRecords.type,
          payload: {
              url: `/Dashboard/GetSmartIndicatorPerformance`,
              appendCurrentReducerKey: "inidicatorsData",
          },
      });
  };

    // Prepare the series and options for the bar chart
    const indicators = {};
    (inidicatorsData?.data?.items ?? []).forEach(item => {
      const description = truncateText(item?.smartIndicatorName,20);
      if (!indicators[description]) {
        indicators[description] = { achieved: 0, target: 0 };
      }
      indicators[description].achieved += parseFloat(item.totalAchieved);
      indicators[description].target += parseFloat(item.totalTarget);
    });
    
    // Convert the thematicAreas object to arrays for the chart
    const series = [
      {
        name: "Achieved",
        data: Object.values(indicators).map(area => area?.achieved),
      },
      {
        name: "Target",
        data: Object.values(indicators).map(area => area?.target),
      },
    ];
    
    const options = {
      chart: {
        type: "bar",
        height: 350,
        legend: {
          show: true,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
          borderRadius: 6,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: Object.keys(indicators), // Thematic area descriptions
      },
      yaxis: {
        title: {
          text: "Number of Beneficiaries",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " beneficiaries"; // Format tooltip
          },
        },
      },
      colors: ["#1DDBA9", "#0E7BB6"],
      grid: {
        show: false,
      },
    };

return (
  <div className="bg-white shadow-sm h-100 fundsDonarDistribution">
    <Label>Smart Indicators</Label>
    <Chart options={options ?? []} series={series ?? []} type="bar" height={300} />
  </div>
);
};

export default Indicator;
