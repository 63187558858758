import React from "react";
import { Route, Routes } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AppContainer from "./components/container/AppContainer";
import LoginForm from "./components/login-signup/LoginForm";
import SignUpForm from "./components/login-signup/SignUpForm";
import "./App.scss";
import ForgetPassword from "./pages/userAccount.js/ForgetPassword";
// import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const { user } = useSelector((state) => state.setting);
  const isLoggedIn = user?.token;

  return (
    <>
      <ToastContainer autoClose={2000} limit={3} />
      <div className="App">
        <Routes>
          {isLoggedIn ? (
            <Route path="/*" element={<AppContainer />} />
          ) : (
            <Route path="/*" element={<LoginForm />} />
          )}
          {!isLoggedIn && <Route path="/login" element={<LoginForm />} />}
          {/* {!isLoggedIn && <Route path="/signup" element={<SignUpForm />} />} */}
          {!isLoggedIn && <Route path="/forget" element={<ForgetPassword />} />}
          {!isLoggedIn && <Route path="/reset" element={<ForgetPassword />} />}
        </Routes>
      </div>
    </>
  );
}

export default App;
