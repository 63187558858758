import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import LogframeColumns from "../../assets/columns/LogframeColumns";
import "./Logframe.scss";
import { FaArrowCircleLeft } from "react-icons/fa";

const Logframe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const { items } = useSelector((state) => state.records.data);

  useEffect(() => {
    dispatch({
      type: "records/getRecords",
      payload: { url: `/LogFrame/GetSmartIndicatorsLogFrame?ProjectId=${id}` },
    });
  }, []);

  const columns = [
    ...LogframeColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`/projects/specific-objective/${row.id}`}>
            <Icon.Folder2Open className="action-icons" />
          </Link>
          {/* <Icon.PencilSquare className="action-icons" />
          <Icon.Trash className="action-icons" /> */}
        </>
      ),
    },
  ];

  return (
    <>
      <hr />
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px" }}>Go Back</span></button>

      <div className="sub d-flex justify-content-between align-items-center px-3 mb-3 mt-2">
        <div>
          <h3>LogFrame</h3>
        </div>
        {/* <div className="right">
          <span className="px-1">
            <Icon.Funnel />
          </span>
          <span>
            <Link to="/projects/add">
              <Icon.PlusCircle />
            </Link>
          </span>
        </div> */}
      </div>
      <div>
        <Table columns={columns} data={items} />
      </div>
    </>
  );
};

export default Logframe;
