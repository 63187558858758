import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";

const FundsDonarDistribution = () => {
  const dispatch = useDispatch();
  const {fundsDonorDistribution} = useSelector((state) => state.records?.current );

  useEffect(() => {
    setTimeout(() => {
      getFundsDonorDistribution();
    }, 200);
  }, []);

  const getFundsDonorDistribution = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetDonorsFundDistribution`,
        appendCurrentReducerKey: "fundsDonorDistribution",
      },
    });
  };
  
  // Prepare the series and options for the pie chart
  const series = fundsDonorDistribution?.data?.items.map(item => item?.funds); // Extract funds for the pie chart
  const labels = fundsDonorDistribution?.data?.items.map(item => item?.fundingOrganization?.name); // Extract organization names for labels
  
  const options = {
    chart: {
      type: "pie",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    labels: labels, 
    legend: {
      show: true,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return  val ; 
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ["#0088ff", "#0E7BB6"], 
  };
  
  return (
    <div className="bg-white shadow-sm h-100 fundsDonarDistribution">
      <Label>Fund's Donor Distribution</Label>
      {fundsDonorDistribution && <Chart options={options ?? {}} series={series ?? []} type="pie" height={300} />}
    </div>
  );
};

export default FundsDonarDistribution;
