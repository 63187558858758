import complaintStatusTypes from "../values/complaintStatusTypes";

export const ComplaintFilterFields = [
  {
    label: "Phone No.",
    placeholder: "Phone No.",
    inSearch: true,
    inForm: true,
    inResult: true,
    wrapperClass: "col-md-4",
    name: "Phone",
    inputType: "number",
    className: "w100",
  },
  {
    label: "CNIC/ POR No.",
    placeholder: "CNIC/ POR No.",
    type: "number",
    name: "CNIC",
    inForm: true,
    wrapperClass: "col-md-4",
  },
  {
    label: "Province",
    placeholder: "Enter Province",
    name: "ProvinceId",
    id: "ProvinceId",
    inputType: "async-dropdown",
    url: "/province/GetAll?DisablePagination=true",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    inResult: true,
  },
  {
    label: "District",
    placeholder: "Enter District",
    name: "DistrictId",
    id: "DistrictId ",
    inputType: "async-dropdown",
    url: "/District/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "province",
      paramField: "ProvinceId",
      action: "useValue",
    },
    inResult: true,
  },
  {
    label: "Tehsil",
    placeholder: "Add Tehsil",
    name: "TehsilId",
    id: "TehsilId",
    inResult: true,
    inputType: "async-dropdown",
    url: "/Tehsil/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "District",
      paramField: "DistrictId",
      action: "useValue",
    },
  },
  {
    label: "Union Council",
    placeholder: "Add Union Council",
    name: "UCId",
    id: "UCId",
    inResult: true,
    inputType: "async-dropdown",
    url: "/UnionCouncil/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "Tehsil",
      paramField: "TehsilId",
      action: "useValue",
    },
  },

  {
    label: "Nature of Complaint",
    placeholder: "Select Nature of Complaint",
    name: "NatureOfComplaintId",
    id: "NatureOfComplaintId",
    inputType: "async-dropdown",
    url: "/NatureOfComplaint/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
  },
  {
    label: "Complaint Mode",
    placeholder: "Select Complaint Mode",
    name: "ModeOfComplaintId",
    id: "ModeOfComplaintId",
    inputType: "async-dropdown",
    url: "/ModeOfComplaint/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
  },
  {
    label: "Status Of Complaint",
    placeholder: "Select Status",
    name: "StatusOfComplaint",
    id: "StatusOfComplaint",
    inputType: "async-dropdown",
    options: complaintStatusTypes,
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    required: true,
    isEnum: true,
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Search",
    inSearch: true,
    wrapperClass: "btn-wrapper ml-auto pr-3 mt-2",
  },
];
