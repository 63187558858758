import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Label from "../../ui/Label";
import { getRecords } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { getPieFormatedDataByArray } from "../../../utils/util";

const TypeOfComplaint = () => {
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const {typeOfComplaint} = useSelector((state) => state.records?.current);
const pieChartFormatData = getPieFormatedDataByArray(typeOfComplaint?.data?.items);

  // const typeOfComplaint = {
  //   values: [0, 9, 0, 91, 0,],
  //   labels: ["Beneficiary", "non-Beneficiary", "SIF Staff", "Partner Staff", "Other"],
  // };


  useEffect(()=>{
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetTypeOfComplainantStats`,
        appendCurrentReducerKey: "typeOfComplaint",
      },
    });
  
  },[])
  return (
    <div className="bg-white shadow-sm recieve-container">
      <Label>Type of Complaint</Label>
      <div className="d-flex justify-content-center">
      <SimplePieChart
        type="donut"
        width={isMobileView ? 160 : 260}
        height={isMobileView ? 240 : 260}
        series={pieChartFormatData?.values ?? "Loading..."}
        labels={pieChartFormatData?.labels ?? "Loading..."}
        position={"bottom"}
        colors={[
          "#ED7D31",
          "#01CC9B",
          "#0075B6",
          "#09909F",
          "#5059AB"
        ]}
      />
      </div>
    </div>
  );
};

export default TypeOfComplaint;
