import React, { useEffect } from "react";
import "./ProjectMVRStatus.scss";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";

const ProjectMVRStatus = () => {
  const dispatch = useDispatch();
  const { projectMVRStatusCount = { data: { items: [] } } } = useSelector((state) => state.records.current || {});
  const isMobileView = useMediaQuery("(max-width: 425px)");

  const series = [
    {
      name: "Pending",
      data: projectMVRStatusCount.data.items.map((item) => item?.pendingCount || 0),
    },
    {
      name: "Ongoing",
      data: projectMVRStatusCount.data.items.map((item) => item?.ongoingCount || 0),
    },
    {
      name: "Completed",
      data: projectMVRStatusCount.data.items.map((item) => item?.completedCount || 0),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "10%",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: projectMVRStatusCount.data.items.map((item) => item?.projectName || "Unknown"),
    },
    yaxis: {
      axisBorder: {
        show: true,
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      offsetX: 40,
      markers: {
        radius: 50,
      },
    },
    grid: {
      show: false,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/Dashboard/GetMVRStatusCount`,
          appendCurrentReducerKey: "projectMVRStatusCount",
        },
      });
    }, 200);
  }, [dispatch]);

  return (
    <div className="bg-white shadow-sm p-3 h-100 mvr-container">
      <h5 className="chart-heading">Project MVR Status</h5>
      <Chart options={options} series={series} type="bar" height={300} />
    </div>
  );
};

export default ProjectMVRStatus;