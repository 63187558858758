import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./Beneficiaries.scss";
import Table from "../../components/table/Table";
import BeneficiaryColumns from "../../assets/columns/BeneficiaryColumns";
import Loader from "../../components/loader/loader";
import { createRecord } from "../../redux/reducers";
import DeleteRow from "../DeleteRow";
import { ORDER_DIRECTION, USER_ROLE } from "../../Constants";
import SearchList from "../../components/search-list/SearchList";
import ListHeader from "../../components/list-header/ListHeader";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import FilterAccordian from "../../components/accordian/FilterAccordian";
import { BeneficiaryFilterFields } from "../../assets/fields/BeneficiaryFilterFields";
import { Modal } from "react-bootstrap";
import MdIcon from "../../components/icon/MdIcon";
import Form from "../../components/form/Form";
import { canView } from "../../utils/util";
import { FaArrowCircleLeft } from "react-icons/fa";

const Beneficiaries = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, created } = useSelector((state) => state.records);
  const { current } = useSelector((state) => state.records);

  const projectBeneficiariesData = current?.projectBeneficiariesData?.data?.items;
  const beneficiariesData = current?.beneficiariesData?.data?.items;

  const formValues = useSelector((state) => state.form.values);
  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);
  const [searchFilterIsOpen, setSearchFilterIsOpen] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();

  useEffect(() => {
    getBeneficiaries();
  }, []);

  useEffect(() => {
    setMeta({ title: "Target Beneficiaries" });
  }, []);

  const getBeneficiaries = () => {
    const beneficiariesListUrl = "/Beneficiary/GetAll";
    // dispatch({
    //   type: "records/getRecords",
    //   payload: {
    //     url: beneficiariesListUrl,
    //     appendCurrentReducerKey: "beneficiariesData",
    //     params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
    //   },
    // });

    if (id) {
      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/ProjectBeneficiary/GetBeneficiaries?ProjectId=${id}`,
          appendCurrentReducerKey: "projectBeneficiariesData",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });
    }
  };

  const columns = [
    ...BeneficiaryColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          {/* <ToolTip label="Details">
            <Link to={`${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip> */}
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          <DeleteRow row={row} url="/Beneficiary" />
        </>
      ),
    },
  ];

  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if(projectBeneficiariesData){
      setSelectedRows(projectBeneficiariesData)
    }
  }, [projectBeneficiariesData])
  
  const fromatedData = function () {
    return projectBeneficiariesData;
  };

  const handleRowSelected = useCallback(({ selectedRows }) => {
    setSelectedRows(prevSelectedRows => {
      if (JSON.stringify(prevSelectedRows) !== JSON.stringify(selectedRows)) {
        return selectedRows;
      }
      return prevSelectedRows;
    });
  }, []);

  const onUpdateProjectBeneficiaries = () => {
    const beneficiaryIds = selectedRows.map(({ id }) => id);
    dispatch({
      type: createRecord.type,
      payload: {
        beneficiaryIds: { value: beneficiaryIds },
        projectId: { value: id },
        url: "/ProjectBeneficiary",
      },
    });
  };

  const toggleModel = () => {
    setModalShow(!modalShow);
  };

  const handleBulkData = () => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...formValues,
        url: "/Beneficiary/ImportBeneficiaries",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
    setModalShow(false);
  };

  return (
    <div className="beneficiaries">
      <div className="d-md-flex py-2  justify-content-between align-items-center border-bottom ">
        {/* <h5>Beneficiaries</h5> */}
        <SearchList
          url="/Beneficiary/GetAll"
          params={{ DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC }}
          searchFieldName="Name"
          placeholder="Search by name"
        />
        <div className="d-flex mt-2">
          {canView([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN], userRoles) && (
            <div className="mr-2 ">
              <ToolTip label="Upload Bulk Data">
                <button
                  className="btn btn-light border border-primary ml-auto d-flex mb-2 align-items-center mr-2"
                  onClick={() => toggleModel()}
                >
                  <Icon.CloudUpload
                    size={20}
                    fill="blue"

                  />
                  <span className="ml-2 text-primary">Upload Bulk Data</span>
                </button>
              </ToolTip>
            </div>
          )}

          <div className="mr-2 ">
            <ToolTip label="Filter">
              <button
                className="btn btn-primary ml-auto d-flex mb-2 align-items-center mr-2"
                onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
              >
                <Icon.Funnel
                  size={20}

                />
                <span className="ml-2">Filter</span>
              </button>
            </ToolTip>
          </div>
          {!id ? (
            <div className="text-right">
              <ListHeader
                options={[
                  {
                    id: 1,
                    name: "PlusCircle",
                    url: "/beneficiaries/add",
                    itemClassName: "btn btn-success d-flex align-items-center justify-content-center",
                    iconColor: "#fff",
                    label: "Add Benficiary",
                    text: "Add Benficiary",
                  },
                ]}
              ></ListHeader>
            </div>
          ) : (
            <button
              onClick={onUpdateProjectBeneficiaries}
              type="button"
              className="btn btn-primary text-light mb-2"
            >
              Update Project Beneficiaries
            </button>
          )}
        </div>
      </div>

      <FilterAccordian
        formFields={BeneficiaryFilterFields}
        url={
          id
            ? `/ProjectBeneficiary/GetBeneficiaries?ProjectId=${id}`
            : "/Beneficiary/GetAll"
        }
        toggleFilter={searchFilterIsOpen}
      />

      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px", padding: "5px" }}>Go Back</span></button>

      <div className="beneficiaryTable">
        <Loader loading={loading} />

        <Table
          columns={id ? BeneficiaryColumns : columns}
          data={fromatedData() ?? []}
          selectableRows={id ? true : false}
          onSelectedRowsChange={handleRowSelected}
          selectableRowSelected={rowSelectCritera}
          canExport={true}
        />
      </div>

      {/* Bulk Data Modal */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="w-100 d-flex justify-content-between"
          >
            <div>Upload Bulk Data</div>
            <div>
              <MdIcon
                iconName={"MdOutlineClose"}
                action={() => setModalShow(!modalShow)}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            formData={[
              {
                label: "Upload File",
                inputType: "file",
                inForm: true,
                onChangeMethod: "onFileChange",
                name: "attachment.Attachment",
                id: "Attachment",
                className: "mx-auto",
                wrapperClass: "col-12",
              },
              {
                inputType: "button",
                buttonType: "submit",
                children: "Save",
                inForm: true,
                wrapperClass: "pr-3 my-3 btn-wrapper col-12",
              },
            ]}
            onSubmit={() => handleBulkData()}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

const rowSelectCritera = (row) => {
  return row.isAdded === true;
};

export default Beneficiaries;
