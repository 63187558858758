import logframeIcon from "./../../assets/images/logframe.svg";
import activityIcon from "./../../assets/images/activity.svg";
import assumptionIcon from "./../../assets/images/assumption_monitoring.svg";
import benifictiaryIcon from "./../../assets/images/beneficiaries.svg";
import projectphotoIcon from "./../../assets/images/project_photos.svg";
import projectcomplaintsIcon from "./../../assets/images/project _complaints.svg";
import projecteditIcon from "./../../assets/images/edit_project.svg";

export const projectMenu = [
  {
    thumbnail: logframeIcon,
    label: "Logframe",
    link: "/projects/logframe",
  },
  {
    thumbnail: activityIcon,
    label: "Activity",
    link: "/activity",
  },
  {
    thumbnail: assumptionIcon,
    label: "Assumption Monitoring",
    link: "/projects/logframe",
  },
  {
    thumbnail: benifictiaryIcon,
    label: "Beneficiaries",
    link: "/beneficiaries",
  },
  {
    thumbnail: projectphotoIcon,
    label: "Project Photos",
    link: "/projects/albums",
  },
  {
    thumbnail: projectcomplaintsIcon,
    label: "Project Complaints",
    link: "/complaints/project",
  },
  {
    thumbnail: projecteditIcon,
    label: "Edit Project",
    link: "/projects/update",
  },
];
