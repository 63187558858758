import React, { useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import {
  ERActivityColumns,
  ExpectedResultColumns,
  SpecificObjectiveColumn,
  SubActivityColumns,
} from "../../assets/columns/ActivityPageColumns";
import "../beneficiaries/Beneficiaries.scss";
import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import SubActivityAchievementModal from "../../components/activities/SubActivityAchievementModal";
import ListHeader from "../../components/list-header/ListHeader";
import { getRecords, putRecord } from "../../redux/reducers";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import SubActivityCompleteModal from "../../components/activities/SubActivityCompleteModal";
import { toast } from "react-toastify";
import { canView } from "../../utils/util";
import { FaArrowCircleLeft } from "react-icons/fa";
import { USER_ROLE } from "../../Constants";

const Activity = () => {
  const { id = "" } = useParams();
  const { setMeta } = usePageMeta();
  const { loading, data, current, created } = useSelector(
    (state) => state.records
  );
  const projectList = current?.projectList?.data?.items;

  const { items = [] } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [subActivityModalIsOpen, setSubActivityModalIsOpen] = useState(false);
  const [completeActivityModalIsOpen, setCompleteActivityModalIsOpen] =
    useState(false);
  const [hideSearchBar, setHideSearchBar] = useState(true);
  const [selectedSubActvity, setSelectedSubActvity] = useState({});
  const [tableData, setTableData] = useState([]);

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles.map(({ name }) => name);

  useEffect(() => {
    getActivities(id);

    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/project/GetAll`,
          appendCurrentReducerKey: "projectList",
        },
      });
    }, 1000);
  }, []);

  useEffect(() => {
    if (created) {
      getActivities(id);
    }
  }, [created]);

  useEffect(() => {
    setMeta({ title: "Activity" });
  }, []);

  useEffect(() => {
    setTableData(items);
  }, [items]);

  useEffect(() => {
    if (created) {
      setSubActivityModalIsOpen();
    }
  }, [created]);

  const getActivities = (id = "") => {
    const showByProject = id ? `?ProjectId=${id}` : "";
    dispatch({
      type: getRecords.type,
      payload: { url: `/LogFrame/GetActivitiesLogFrame${showByProject}` },
    });
  };

  function onAddSubActivityAchievement(params) {
    setSubActivityModalIsOpen(true);
    setSelectedSubActvity(params);
  }
  function onAddSubActivityComplete(params) {
    setCompleteActivityModalIsOpen(true);
    setSelectedSubActvity(params);
  }

  const handleActivityApprove = (id) => {
    dispatch({
      type: putRecord.type,
      payload: { url: `/SubActivity/api/SubActivity/Approve?id=${id}` },
    });
  };

  const subActivityActionCol = [
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Add">
            <Icon.PlusCircle
              className="action-icons"
              onClick={() => onAddSubActivityAchievement(row)}
            />
          </ToolTip>
          {/* < Icon.Trash className="action-icons" /> */}
        </>
      ),
    },
    {
      name: "Submit",
      selector: (row) => (
        <>
          {
            <ToolTip
              label={row?.isApproved ? "Already Submitted" : "Submit Activity"}
            >
              <Icon.FileCheck
                className={
                  row?.isApproved
                    ? "text-secondary"
                    : "cursor-pointer text-success"
                }
                onClick={() => row?.isApproved && onAddSubActivityComplete(row)}
              />
            </ToolTip>
          }
        </>
      ),
    },
    canView([USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN], userRoles) && {
      name: "Approve",
      selector: (row) => (
        <>
          {
            <ToolTip
              label={row?.isApproved ? "Already Approved" : "Approve Activity"}
            >
              <Icon.Check2Circle
                className={
                  row?.isApproved
                    ? "text-secondary"
                    : "cursor-pointer text-success"
                }
                onClick={() => {
                  !row?.isApproved && handleActivityApprove(row?.id);
                }}
              />
            </ToolTip>
          }
        </>
      ),
    },
  ];

  const onRowExpand = ({ id }, isExpanded = false, rowType) => {
    if (rowType === ROW_TYPE.EXPECTED_RESULT) {
      let newItems = tableData.map((so) => {
        let expectedResults = so.expectedResults.map((er) =>
          er.id == id ? { ...er, isExpanded } : { ...er }
        );
        return { ...so, expectedResults };
      });
      setTableData(newItems);
    } else {
      let newItems = tableData.map((so) => {
        let expectedResults = so.expectedResults.map((er) => {
          let activities = er.activities.map((ac) => {
            return ac?.id == id ? { ...ac, isExpanded } : { ...ac };
          });
          return { ...er, activities };
        });
        return { ...so, expectedResults };
      });
      setTableData(newItems);
    }
  };

  const ROW_TYPE = {
    EXPECTED_RESULT: "EXPECTED_RESULT",
    ACTIVITY: "ACTIVITY",
  };

  return (

    <>
          <hr />
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px" }}>Go Back</span></button>
      
      <SubActivityAchievementModal
        modalIsOpen={subActivityModalIsOpen}
        selectedSubActvity={selectedSubActvity}
        toggleSubActivityAchievementModal={() =>
          setSubActivityModalIsOpen(false)
        }
      />
      <SubActivityCompleteModal
        modalIsOpen={completeActivityModalIsOpen}
        selectedSubActvity={selectedSubActvity}
        toggleSubActivityAchievementModal={() =>
          setCompleteActivityModalIsOpen(false)
        }
      />
      <div className="mb-3">
        <div className="d-md-flex w-100 justify-content-end">
          <div className={`${hideSearchBar ? "d-none" : ""} `}>
            <select
              className="form-control"
              style={{ width: "250px" }}
              onChange={({ target }) => getActivities(target.value)}
            >
              <option disabled selected>
                Filter by Project
              </option>
              {projectList &&
                projectList.map(({ title, id }) => (
                  <option value={id} key={id}>
                    {title}
                  </option>
                ))}
            </select>
          </div>
          {/* <SearchList url="/LogFrame/GetActivitiesLogFrame" params={{ DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC }} searchFieldName="search.value" /> */}
          <div className="py-2 d-flex align-items-center">
            <ListHeader
            // options={[{ id: 3, name: "PlusCircle", url: "/activity/add" }]}
            >
              <span
                className="px-3"
                onClick={() => setHideSearchBar(!hideSearchBar)}
              >
                <span className="badge badge-primary p-2 mr-2  ">
                  Filter Activities:
                </span>
                <ToolTip label="Filter Activities">
                  <Icon.Funnel className="cursor-pointer" />
                </ToolTip>
              </span>
            </ListHeader>
          </div>
        </div>
      </div>
      <div>
        <Loader loading={loading} />
        <Table
          columns={SpecificObjectiveColumn}
          data={tableData}
          expandableRows
          expandableRowsComponent={(row) => (
            <Table
              columns={ExpectedResultColumns}
              data={row?.data?.expectedResults}
              expandableRowExpanded={(row) => row?.isExpanded}
              onRowExpandToggled={(isExpanded, erItem) =>
                onRowExpand(erItem, isExpanded, ROW_TYPE.EXPECTED_RESULT)
              }
              // noTableHead={!!data}
              expandableRows
              expandableRowsComponent={(row) => (
                <Table
                  columns={ERActivityColumns}
                  data={row?.data?.activities}
                  expandableRowExpanded={(row) => row?.isExpanded}
                  onRowExpandToggled={(isExpanded, eraItem) =>
                    onRowExpand(eraItem, isExpanded, ROW_TYPE.ACTIVITY)
                  }
                  expandableRows
                  expandableRowsComponent={(row) => (
                    <Table
                      columns={[...subActivityActionCol, ...SubActivityColumns]}
                      data={row?.data?.subActivities}
                    />
                  )}
                />
              )}
            />
          )}
        />
      </div>
    </>
  );
};
export default Activity;
