import React, { useEffect, useState } from "react";
import "./DashboardNavBar.scss";
import { CaretRightFill, Funnel } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { ACTIVE_TAB } from "../../Constants";
import Accordion from "react-bootstrap/Accordion";
import ToolTip from "../tooltip/Tooltip";
import ReactSelect from "react-select";
import { getRecords } from "../../redux/reducers";
import { Form } from "react-bootstrap";
import FilterAccordian from "../accordian/FilterAccordian";
import * as Icon from "react-bootstrap-icons";
import DashboardFilters from "./DashboardFilters";
import { DashboardFilterFields } from "../../assets/fields/DashboardFilterFields";

const DashboardTopNavBar = () => {
  const dispatch = useDispatch();
  const { currentActiveTab } = useSelector((state) => state?.setting?.filter);
  const MainDashboardPagefiltersItems = [
    {
      url: "/Dashboard/GetBeneficiaryCountByActivities",
      appendCurrentReducerKey: "activitiesCount",
    },
    {
      url: `/Dashboard/GetProjectStatus`,
      appendCurrentReducerKey: "topInfo",
    },
    {
      url: `/Dashboard/GetActivitiesCount`,
      appendCurrentReducerKey: "activitiesCountData",
    },
    {
      url: `/Dashboard/GetFundingOrganizationCount`,
      appendCurrentReducerKey: "fundingProjectCountData",
    },
    {
      url: `/Dashboard/GetAgeRangeGroupCount`,
      appendCurrentReducerKey: "ageRangeGendersCount",
    },
    {
      url: `/Dashboard/GetBeneficiaryCountByGenders`,
      appendCurrentReducerKey: "gendersCount",
    },

    {
      url: `/Dashboard/GetActivityPercentagePerProject`,
      appendCurrentReducerKey: "projectPercentageData",
    },
    {
      url: "/Dashboard/GetSDGProjectCount",
      appendCurrentReducerKey: "sdgChartData",
    },
    {
      url: `/Dashboard/GetOverlappingBeneficiaries`,
      appendCurrentReducerKey: "OverlappingBeneficiaries",
    },
    {
      url: `/Dashboard/GetMVRStatusCount`,
      appendCurrentReducerKey: "projectMVRStatusCount",
    },
    {
      url: `/Dashboard/GetBeneficiaryCountByThematicArea`,
      appendCurrentReducerKey: "BeneficiaryCountByThematicArea",
    },
    {
      url: `/Dashboard/GetActivityCountByThematicArea`,
      appendCurrentReducerKey: "ActivityCountByThematicArea",
    },
    {
      url: `/Dashboard/GetDonorsFundDistribution`,
      appendCurrentReducerKey: "fundsDonorDistribution",
    },
    {
      url: `/Dashboard/GetTargetVsAcheivedBeneficaries`,
      appendCurrentReducerKey: "targetVsAchievedBenif",
    }
  ];

  const [showDropdown, setShowDropdown] = useState(false);
  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const tabs = [
    ACTIVE_TAB.Dashboard,
    ACTIVE_TAB["Geographical Data"],
    ACTIVE_TAB.Indicators,
    ACTIVE_TAB.Activities,
    ACTIVE_TAB.Complaints,
  ];

  function setActiveTab(tab) {
    dispatch({
      type: "setting/setFilter",
      payload: {
        currentActiveTab: tab,
      },
    });
  }



  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
      <div className="container-fluid">
        <div className="navbar-nav">
          {tabs.map((tab, index) => (
            <div key={index}>
              <button className="nav-btn" onClick={() => setActiveTab(tab)}>
                {tab}
              </button>
              {currentActiveTab === tab && (
                <div className="nav-indicator"></div>
              )}
            </div>
          ))}
        </div>

        <div className="mr-2">
          <ToolTip label="Filter">
            <div className="dropdown">
              <button
                className="btn btn-primary ml-auto d-flex mb-2 align-items-center dropdown-toggle"
                onClick={handleToggleDropdown}
                aria-expanded={showDropdown}
              >
                <Icon.Funnel size={20} />
                <span className="ml-2">Filter</span>
              </button>
              <DashboardFilters
                showDropdown={showDropdown}
                handleClose={handleToggleDropdown}
                formFields={DashboardFilterFields}
                url={MainDashboardPagefiltersItems}
              />
            </div>
          </ToolTip>
        </div>

      </div>

    </nav>
  );



  // return (
  //   <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
  //     <div className="container-fluid">
  //       <div className="navbar-nav">
  //         {tabs.map((tab, index) => (
  //           <div key={index}>
  //             <button className="nav-btn" onClick={() => setActiveTab(tab)}>
  //               {tab}
  //             </button>
  //             {currentActiveTab === tab && (
  //               <div className="nav-indicator"></div>
  //             )}
  //           </div>
  //         ))}
  //       </div>

  //       <div class="dropdown">
  //         {/* <ToolTip label="Filter"> */}
  //         <button
  //           className="filter-btn dropdown-toggle"
  //           type="button"
  //           data-bs-toggle="dropdown"
  //           aria-expanded="false"
  //           data-bs-auto-close="outside"
  //         >
  //           <Funnel /> Filters
  //         </button>
  //         {/* </ToolTip> */}

  //         <div class="dropdown-menu mt-4">
  //           <label
  //             style={{
  //               fontSize: "20px",
  //             }}
  //             className="label-heading mt-4"
  //           >
  //             Filters
  //           </label>

  //           <div className="d-flex flex-column mt-3">
  //             <label className="label-heading">Select Dates</label>
  //             <div className="filters-field-container">
  //               <input
  //                 className="filters-field w-50"
  //                 type="date"
  //                 id="date"
  //                 name="startDate"
  //                 placeholder="Start Date"
  //                 onChange={(e) => handleChangeFilterInputs(e)}
  //               />
  //               <input
  //                 className="filters-field w-50"
  //                 type="date"
  //                 id="date"
  //                 placeholder="End Date"
  //                 name="endDate"
  //                 onChange={(e) => handleChangeFilterInputs(e)}
  //               />
  //             </div>
  //           </div>
  //           <div className="d-flex flex-column mt-3">
  //             <label className="label-heading">Select Sectors</label>
  //             <select className="drop-down" name="sectors" id="sectors">
  //               <option value="volvo">A</option>
  //               <option value="saab">B</option>
  //               <option value="opel">C</option>
  //               <option value="audi">D</option>
  //             </select>
  //           </div>
  //           <div className="d-flex flex-column mt-3">
  //             <label className="label-heading">Select SDGs</label>
  //             <select className="drop-down" name="sectors" id="sectors">
  //               <option value="volvo">A</option>
  //               <option value="saab">B</option>
  //               <option value="opel">C</option>
  //               <option value="audi">D</option>
  //             </select>
  //           </div>
  //           <div className="d-flex flex-column mt-3">
  //             <label className="label-heading">Select Project</label>


  //             <select className="drop-down" name="sectors" id="sectors">
  //               <option value="volvo">A</option>
  //               <option value="saab">B</option>
  //               <option value="opel">C</option>
  //               <option value="audi">D</option>
  //             </select>


  //             {/* {LIST_OF_ACTIVITIES.map((project, projectIndex) => {
  //               return (
  //                 <div
  //                   key={projectIndex}
  //                   className="accordion"
  //                   id={`accordionExample${projectIndex}`}
  //                 >
  //                   <p
  //                     className="accordion-heading"
  //                     type="button"
  //                     data-bs-toggle="collapse"
  //                     data-bs-target={`#collapse${projectIndex}`}
  //                     aria-expanded={false}
  //                     aria-controls={`collapse${projectIndex}`}
  //                   >
  //                     <span className="mr-1">

  //                       <CaretRightFill />
  //                     </span>
  //                     {project.projectTitle}
  //                   </p>
  //                   <div
  //                     id={`collapse${projectIndex}`}
  //                     className="accordion-collapse collapse"
  //                     aria-labelledby={`heading${projectIndex}`}
  //                     data-bs-parent={`#accordionExample${projectIndex}`}
  //                   >
  //                     {project.activities.map((activity, activityIndex) => {
  //                       return (
  //                         <div className="accordion-body" key={activityIndex}>{activity.name}</div>
  //                       );
  //                     })}
  //                   </div>
  //                 </div>
  //               );
  //             })} */}
  //           </div>

  //         </div>
  //       </div>
  //     </div>
  //   </nav>
  // );
};

export default DashboardTopNavBar;
