import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import SmartIndicatorColumns from "../../assets/columns/SmartIndicatorColumns";

import { smartIndicatorFields } from "../../assets/fields/smartIndicatorFields";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import DeleteRow from "../../pages/DeleteRow";
import { createRecord, formatRecords, putRecord } from "../../redux/reducers";
import Form from "../form/Form";
import Loader from "../loader/loader";
import Table from "../table/Table";
import "./SmartIndicator.scss";
import { useParams } from "react-router-dom";

const SmartIndicator = ({
  type = INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE,
}) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const { data, created, creating, loading } = useSelector(
    (state) => state.records
  );

  
let {id} = useParams();
 const projectId = id;
  console.log("🚀 ~ projectId:", projectId)
  
  
  const soData = data?.items;
  const SM_URL =
    type == INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE
      ? "/ILSmartIndicator/GetSOSmartIndicators"
      : "/ILSmartIndicator/GetERSmartIndicators";
  const IL_URL =
    type == INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE
      ? "/InterventionLogic/GetSpecificObjectives"
      : "/InterventionLogic/GetExpectedResults";
  ///ILSmartIndicator/GetAll?ProjectId=${projectId}

  useEffect(() => {
    
      getSmartIndicatorsByProjectId();
    
  }, [projectId]);


  

  useEffect(() => {
    if (created) {
      // setTimeout(() => {
      getSmartIndicatorsByProjectId();
      closeModal();
      // }, 1000);
    }
  }, [created]);

  function getSmartIndicatorsByProjectId() {
   if(projectId){
    dispatch({
      type: "records/getRecords",
      payload: { url: `${SM_URL}?ProjectId=${projectId}` },
    });
   }
  }



  function openSmartIndicatorModal(row) {
    if (row) {
      setCurrentRow(row);
      const overridePayload = {
        interventionLogic:
          type == INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE
            ? row?.specificObjective
            : row?.expectedResult,
      };
      dispatch({
        type: formatRecords.type,
        payload: { ...row, ...overridePayload },
      });
    }
    setIsOpen(true);
  }

  function afterOpenSmartIndicatorModal() {}

  function closeModal() {
    setIsOpen(false);
    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
  }

  function formatFields() {
    let fields = smartIndicatorFields;
    return fields.map((field) => {
      if (field.name == "interventionLogic")
        return {
          ...field,
          url: `${IL_URL}?ProjectId=${projectId}`,
          label:
            type == INTERVENTION_LOGIC_TYPE.EXPECTED_RESULT
              ? "Expected Result"
              : field.label,
        };
      return field;
    });
  }

  const columns = [
    ...SmartIndicatorColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Icon.PencilSquare
            className="action-icons"
            onClick={() => openSmartIndicatorModal(row)}
          />
          <DeleteRow row={row} url="/ILSmartIndicator" />
        </>
      ),
    },
  ];

  function postData(params) {
    if (currentRow) {
      dispatch({
        type: putRecord.type,
        payload: { ...params, url: "/ILSmartIndicator" },
      });
    } else {
      dispatch({
        type: createRecord.type,
        payload: { ...params, url: "/ILSmartIndicator" },
      });
    }
  }

  return (
    <div className="table-wrapper smart-indicator mt-4">
      <div className="d-sm-flex">
        <h5 className="font-weight-bold">Smart Indicator Details</h5>
        <button
          className="btn btn-primary btn-sm ml-auto mb-2"
          onClick={() => openSmartIndicatorModal()}
        >
          <Icon.Plus /> Add Smart Indicator
        </button>
      </div>
      {/* <Loader loading={loading} /> */}
      <Table columns={columns} data={soData} />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenSmartIndicatorModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Smart Indicator"
      >
        <Icon.XCircle onClick={closeModal} className="action-icons" />
        <h4>Smart Indicator</h4>
        <Form
          formData={formatFields()}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </div>
  );
};

export default SmartIndicator;
