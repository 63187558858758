// import React, { useEffect, useState } from "react";
// import "./ActivitiesChart.scss";
// import Chart from "react-apexcharts";
// import ReactSelect from "react-select";
// import { useDispatch, useSelector } from "react-redux";
// import { clearForm, getRecords, resetData } from "../../../redux/reducers";

// const ActivitiesChart = () => {
//   const dispatch = useDispatch();
//   const { projectList, projectData } = useSelector((state) => state.records.current);
//   console.log("🚀 ~ ActivitiesChart ~ projectData:", projectData)
//   const [projectListData, setProjectListData] = useState(projectList?.data?.items ?? []);
//   const [selectedProjectID, setSelectedProjectID] = useState(null);
//   console.log("🚀 ~ ActivitiesChart ~ selectedProjectID:", selectedProjectID)

//   const activityNames = projectData?.data?.items?.map(item => item?.activityName);
//   const totalTargets = projectData?.data?.items?.map(item => item?.totalTargetForActivity);
//   const totalAchieved = projectData?.data?.items?.map(item => item?.totalTargetAchieved);
//   const percentages = projectData?.data?.items?.map(item => item?.percentage);
//   const projectTitle = projectData?.data?.items[0]?.projectTitle;



//   useEffect(() => {
//     setTimeout(() => {
//       getProjectListData();
//     }, 200)
//   }, [])


//   useEffect(() => {
//     dispatch({
//       type: getRecords.type,
//       payload: {
//         url: `/Dashboard/GetActivityVsTarget?Project.Id=${selectedProjectID}&DisablePagination=false`,
//         appendCurrentReducerKey: "projectData",
//       },
//     });
//   }, [selectedProjectID])

//   const getProjectListData = () => {
//     dispatch({
//       type: getRecords.type,
//       payload: {
//         url: `/Project/GetAll?DisablePagination=true`,
//         appendCurrentReducerKey: "projectList",
//       },
//     });
//   }





//   // let options = {
//   //   series: [
//   //     {
//   //       name: "Activities",
//   //       data: projectData?.data?.items?.map((item) => ({
//   //         x: item.activityName,
//   //         y: {
//   //           min: item.totalTargetAchieved,
//   //           max: item?.totalTargetForActivity,
//   //         percentage: item?.percentage
//   //        }
//   //       })) || [],
//   //     },
//   //   ],
//   //   chart: {
//   //     height: 350,
//   //     type: "rangeBar",
//   //   },
//   //   plotOptions: {
//   //     bar: {
//   //       horizontal: true,
//   //     },
//   //   },
//   //   dataLabels: {
//   //     enabled: true,
//   //     formatter: function (val, opts) {
//   //       const diffDays = Math.round((val[1] - val[0]) / (1000 * 60 * 60 * 24));
//   //       return `${diffDays} days`;
//   //     },

//   //   },
//   //   xaxis: {
//   //     type: "datetime",
//   //   },
//   //   legend: {
//   //     position: "top",
//   //   },
//   // };

//   const chartOptions = {
    
//     chart: {
//       type: 'rangeBar',
//       height: 500,
//       borderWidth: 1,
//       borderStyle: 'solid',
//       borderRadius: '5px',
      
//     },
//     plotOptions: {
//       bar: {
//         horizontal: true,
//         barHeight: 20,
//         dataLabels: {
//           position: 'top',
//         },
//       },
//       legend: {
//         show: true
//       },


//     },
//     dataLabels: {
//       enabled: true,
//       offsetX: -6,
//       style: {
//         fontSize: '12px',
//         colors: ['#fff']
//       }
//     },

//     stroke: {
//       show: true,
//       width: 1,
//       colors: ['#fff']
//     },

//     tooltip: {
//       shared: true,
      
//     },

//     xaxis: {
//       categories: activityNames,
//     },
//     title: {
//       text: projectTitle ?? "Please Select a Project from above",
//     },
//   };

//   const series = [
//     // {
//     //   name: 'Total Target',
//     //   data: totalTargets,
//     // },
//     // {
//     //   name: 'Total Achieved',
//     //   data: totalAchieved,
//     // },
//     {
//       name: 'Percentage',
//       data: percentages,
//     },
//   ];

//   return (
//     <div className="bg-white shadow-sm activityChart-container h-100 col-md-12">

//       <ReactSelect className="col-md-6" placeholder="Select Project" options={projectListData?.map((items, index) => ({ value: items?.id, label: items?.title }))} onChange={(e) => setSelectedProjectID(e?.value)} />


//       <h5 className="fw-bold px-3 my-2">Activity Map</h5>

//       {projectData?.data?.items?.length > 0 ? <Chart options={chartOptions} series={series} type="bar" height={500} /> : <p className="text-center">No Data Found</p>}


//       {/* <Chart
//         options={projectData?.data?.items?.map((item) => ({
       
//          target: item?.totalTargetForActivity,
//          achieved: item?.totalTargetAchieved,
//          percentage: item?.percentage
       
//         })) || []}
//         series={options.series}
//         type="rangeBar"
//         height={"90%"}
//       /> */}
//     </div>
//   );
// };

// export default ActivitiesChart;


import React, { useEffect, useState } from "react";
import "./ActivitiesChart.scss";
import Chart from "react-apexcharts";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, getRecords, resetData } from "../../../redux/reducers";

const ActivitiesChart = () => {
  const dispatch = useDispatch();
  const { projectList, projectData } = useSelector((state) => state.records.current);
  const [projectListData, setProjectListData] = useState(projectList?.data?.items ?? []);
  const [selectedProjectID, setSelectedProjectID] = useState(null);

  const activityNames = projectData?.data?.items?.map(item => item?.activityName);
  const totalTargets = projectData?.data?.items?.map(item => item?.totalTargetForActivity);
  const totalAchieved = projectData?.data?.items?.map(item => item?.totalTargetAchieved);
  const percentageData = projectData?.data?.items?.map(item => item?.percentage);
  const percentages = projectData?.data?.items?.map(item => Math.min(item?.percentage, 100)); // Ensure percentage doesn't exceed 100%
  const projectTitle = projectData?.data?.items[0]?.projectTitle;

  useEffect(() => {
    setTimeout(() => {
      getProjectListData();
    }, 200)
  }, [])

  useEffect(() => {
    if (selectedProjectID) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/Dashboard/GetActivityVsTarget?Project.Id=${selectedProjectID}&DisablePagination=false`,
          appendCurrentReducerKey: "projectData",
        },
      });
    }
  }, [selectedProjectID])

  const getProjectListData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/GetAll?DisablePagination=true`,
        appendCurrentReducerKey: "projectList",
      },
    });
  }

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 500,
     
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '50%',
        legend:{
          show: true
        }
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
      offsetX: -10,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
      
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // return `<div class="arrow_box bg-white p-2">
        //           <span class=" text-danger" style={font-weight:"bold"}>Total Target: ${totalTargets[dataPointIndex]}</span><br/>
        //           <span class=" text-success" style={font-weight:"bold"}>Total Achieved: ${totalAchieved[dataPointIndex]}</span><br/>
        //           <span class=" " style={font-weight:"bold"}>Percentage: ${percentageData[dataPointIndex]}%</span>
        //         </div>`;
       return `
       <div
  style="
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    font-family: Arial, sans-serif;
    position: relative;
    z-index: 1000;
  "
>
  <span
    style="
      font-weight: bold;
      color: red;
      display: block;
      margin-bottom: 5px;
    "
  >
    Total Target: <span style="font-weight: bold; color:red;"> ${totalTargets[dataPointIndex]}</span>
  </span>
  <span
    style="
      font-weight: bold;
      color: green;
      display: block;
      margin-bottom: 5px;
    "
  >
    Total Achieved: <span style="font-weight: bold;color: green;">${totalAchieved[dataPointIndex]}</span>
  </span>
  <span
    style="
      font-weight: bold;
      display: block;
      color:blue;
    "
  >
    Percentage: <span style="font-weight: bold; color:blue;">${percentageData[dataPointIndex]}%</span>
  </span>
</div>

       `

      }
    },
    xaxis: {
      categories: activityNames,
      max: 100,
    },
    title: {
      text: projectTitle ?? "Please Select a Project from above",
    },

  
  };

  const series = [
    {
      name: 'Percentage',
      data: percentages,
    },
  ];

  return (
    <div className="bg-white shadow-sm activityChart-container h-100 col-md-12">
      <ReactSelect className="col-md-6" placeholder="Select Project" options={projectListData?.map((items) => ({ value: items?.id, label: items?.title }))} onChange={(e) => setSelectedProjectID(e?.value)} />
      <h5 className="fw-bold px-3 my-2">Activity Map</h5>
      {projectData?.data?.items?.length > 0 ? <Chart options={chartOptions} series={series} type="bar" height={500} /> :
       <p className="h-75 d-flex align-items-center justify-content-center text-center " style={{fontSize: "20px", fontWeight: "bold"}}>No Data Found</p>}
    </div>
  );
};

export default ActivitiesChart;
