import complaintStatusTypes from "../values/complaintStatusTypes";

export const feedbackResolutionFields = [
  {
    name: "actionTaken",
    label: "Action Taken",
    placeholder: "Enter action taken",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    name: "outcome",
    label: "Outcome",
    placeholder: "Enter Outcome",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    label: "Status Of Complaint",
    placeholder: "Select Status",
    name: "statusOfComplaint",
    id: "statusOfComplaint",
    inputType: "async-dropdown",
    options: complaintStatusTypes,
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    required: true,
    isEnum: true,
  },
  {
    label: "Complaint Resolving Date",
    placeholder: "Enter Start Date",
    name: "complaintResolvingDate",
    inputType: "date",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    label: "Days To Resolve Complaint",
    placeholder: "Enter days",
    name: "noOfDaysToResolveComplaint",
    inForm: true,
    type: "number",
    wrapperClass: "col-md-6",
  },
  {
    label: "Designated Person",
    placeholder: "Select Person",
    name: "designatedPerson",
    id: "designatedPerson",
    inputType: "async-dropdown",
    url: "/UserManagement/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    required: true,
  },
  {
    label: "Communicating Person",
    placeholder: "Select Person",
    name: "communicatingPerson",
    id: "communicatingPerson",
    inputType: "async-dropdown",
    url: "/UserManagement/GetAll",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    required: true,
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "mr-3 btn-wrapper position-right mt-2",
  },
];
