import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import "./AgeandGenderView.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import { getPieFormatedData } from "../../../utils/util";

const AgeandGenderView = () => {
  const isMobileView = useMediaQuery("(max-width: 425px)");
  const isTabView = useMediaQuery("(min-width: ) and (max-width: 768px)");
  const dispatch = useDispatch();
  const {
    gendersCount = { data: {} }, 
    ageRangeGendersCount = { data: { items: [] } }
  } = useSelector((state) => state.records.current || {});

  useEffect(() => {
    setTimeout(() => {
      getBeneficiaryCountByGenders();
      getAgecountData();
    }, 200);
  }, []);

  const getBeneficiaryCountByGenders = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetAgeRangeGroupCount`,
        appendCurrentReducerKey: "ageRangeGendersCount",
      },
    });
  };

  const getAgecountData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByGenders`,
        appendCurrentReducerKey: "gendersCount",
      },
    });
  };

  const getRangeGenderLabels = () => {
    const labels = [];
    ageRangeGendersCount.data.items.forEach((item) => {
      labels.push(item?.ageRangeName || "Unknown");
    });
    return labels;
  };

  const getRangeGenderData = () => {
    const data = [];
    ageRangeGendersCount.data.items.forEach((item) => {
      data.push(item?.ageRangeCount || 0);
    });
    return data;
  };

  const ageCountData = {
    values: getRangeGenderData(),
    labels: getRangeGenderLabels(),
  };

  const genderCountData = {
    values: [
      gendersCount.data.male || 0, 
      gendersCount.data.female || 0, 
      gendersCount.data.transgender || 0
    ],
    labels: ["Male", "Female", "Others"],
  };

  const sectorWiseProjects = {
    values: [10, 20],
    labels: ["WASH", "Education"],
  };

  return (
    <div className="bg-white charts-view h-100 shadow-sm justify-content-evenly">
      {ageCountData ? (
        <div className="d-flex flex-column justify-content-center align-item-center">
          <div className="px-4">
            <Label>Age</Label>
          </div>
          <SimplePieChart
            type="donut"
            width={isMobileView ? 140 : 240}
            height={isMobileView ? 240 : 260}
            series={ageCountData.values}
            labels={ageCountData.labels}
            position={"bottom"}
          />
        </div>
      ) : (
        "Loading..."
      )}
      <div className="seprator"></div>
      <div className="d-flex flex-column justify-content-center align-item-center">
        <div className="px-4">
          <Label>Gender</Label>
        </div>
        {genderCountData && (
          <SimplePieChart
            type="donut"
            width={isMobileView ? 140 : 240}
            height={isMobileView ? 240 : 260}
            series={genderCountData.values}
            labels={genderCountData.labels}
            position={"bottom"}
            colors={["#1470A2", "#C7233B", "#FCC028"]}
          />
        )}
      </div>
      <div className="seprator"></div>
      {/* TODO Make below pie chart dynamic */}
      <div className="d-flex flex-column justify-content-center align-item-center">
        <div className="px-4">
          <Label>Sector Wise Projects</Label>
        </div>
        {sectorWiseProjects && (
          <SimplePieChart
            type="donut"
            width={isMobileView ? 140 : 240}
            height={isMobileView ? 240 : 260}
            series={sectorWiseProjects.values}
            labels={sectorWiseProjects.labels}
            position={"bottom"}
            colors={["#FF7750", "#0E7BB6"]}
          />
        )}
      </div>
    </div>
  );
};

export default AgeandGenderView;