import React from 'react'
import './laoder.scss';
import loadingGif from './../../assets/images/loader.gif';

const Loader = ({ loading, otherProps, text = '', overlay = true, theme = 'dark' }) => {
    return (
        <>{loading && <div className={`wrapper ${overlay && 'overlay'} ${theme}`} style={{height: '100vh'}} {...otherProps}>
            <img src={loadingGif} alt="loading..." style={{maxWidth: 90}} />
            {text}
        </div>}</>
    )
}

export default Loader;