const FundingOrganizationColumns = [
  {
    name: "Organization Name",
    selector: (row) => row?.fundingOrganization?.name,
  },
  {
    name: "Amount",
    selector: (row) => row?.amount,
  },
  // {
  //   name: "Total Amount Left",
  //   selector: (row) => row?.totalAmountLeft,
  // },
  {
    name: "Remarks",
    selector: (row) => row?.remarks,
  },
];

export default FundingOrganizationColumns;
