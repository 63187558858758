import { Link } from "react-router-dom";
import Badge from "../../components/badge/Badge";

const UserManagementColumns = [
  {
    name: "User Name",
    selector: (row) => (
      <>
        <Link to={`${row?.id}`} className="text-capitalize">
          {row?.name}
        </Link>
      </>
    ),
  },
  {
    name: "Email",
    selector: (row) => row?.email,
  },
  {
    name: "Phone No.",
    selector: (row) => row?.phoneNumber,
  },
  {
    name: "Role",
    selector: (row) =>
      row?.roles?.map(({ name }, index) => (
        <Badge
          className="m-1 text-left btn btn-sm btn-info"
          style={{ lineHeight: "10px", fontSize: "11px" }}
          key={index}
        >
          {name}
        </Badge>
      )),
  },
  // {
  //   name: "Image URL",
  //   selector: (row) => row?.imageUrl,
  // },
  // {
  //   name: "Device ID",
  //   selector: (row) => row?.deviceId,
  // },
  // {
  //   name: "isApproved",
  //   selector: (row) =>
  //     <label className={getStatusStyle(row?.isApproved = "false")}>
  //       {row?.status.charAt(0).toUpperCase() + row?.isApproved.slice(1)}
  //     </label>
  // },
];

export default UserManagementColumns;
