import React from "react";
import { useDispatch } from "react-redux";
import Form from "../../components/form/Form";
import { createRecord } from "../../redux/reducers";

const ChangePassword = () => {
  const ChangePasswordFields = [
    {
      name: "currentPassword",
      inputType: "password",
      label: "Current Password",
      placeholder: "Current Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      name: "newPassword",
      inputType: "password",
      label: "New Password",
      placeholder: "New Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      name: "confirmNewPassword",
      inputType: "password",
      placeholder: "Confirm Password",
      label: "Confirm Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      name: "changePassword",
      inputType: "button",
      buttonType: "submit",
      children: "Change Password",
      className: "col-12",
      wrapperClass: "mr-3 btn-wrapper",
    },
  ];

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...values,
        url: "/Account/ChangePassword",
      },
    });
  };

  return (
    <div className="m-md-5 px-md-5" style={{ minHeight: "100vh" }}>
      <Form
        formData={ChangePasswordFields}
        onSubmit={(params) => handleSubmit(params)}
      />
    </div>
  );
};

export default ChangePassword;
