import React from 'react'
import { Link } from 'react-router-dom'

const ColumnLink = ({children="",className="",...props}) => {
  return (
    <Link className={`text-capitalize text-primary ${className}`} {...props}>
        {children}
    </Link>
  )
}

export default ColumnLink