import React from "react";
import './Upload.scss';

const Upload = ({ className = '', value, ...props }) => {
    const getValue = () => {
        return value && value.replace(/^.*[\\\/]/, '')
    }
    return (
        <>
            <input {...props} className={className} value="" type="file" />
            {getValue()}
        </>
    );
};

export default Upload;
