import targetTypes from "../values/targetTypes";

export const subActivityFields = [
  {
    "label": "Activity",
    "placeholder": "Select Activity",
    "name": "activity",
    "id": "activity",
    "inputType": "async-dropdown",
    "url": "/ERActivity/GetActivities",
    "searchAttrib": "name",
    "labelAttribute": "name",
    "valueAttribute": "id",
    "onChangeMethod": "asyncdropdown",
    "inForm": true,
    "wrapperClass": "col-6",
    "isMulti": false,
    required: true,
  },
  {
    "label": "Sub Activity Name",
    "placeholder": "Add Activity Name",
    "inputType": "textarea",
    "inForm": true,
    "name": "name",
    "wrapperClass": "col-6"
  },
  {
    "label": "Start Date",
    "placeholder": "Enter Start Date",
    "name": "startDate",
    "inputType": "date",
    "inForm": true,
    "wrapperClass": "col-6"
  },
  {
    "label": "End Date",
    "placeholder": "Enter End Date",
    "name": "endDate",
    "inputType": "date",
    "inForm": true,
    "wrapperClass": "col-6"
  },
  {
    "label": "Sub Activity Target",
    "placeholder": "Enter Target",
    "name": "target",
    "inForm": true,
    "type": "number",
    "wrapperClass": "col-6"
  },
  {
    "label": "Target Type",
    "placeholder": "Target Type",
    "name": "targetType",
    // "inputType": "dropdown",
    "options": targetTypes,
    "onChangeMethod": "dropdown",
    "inForm": true,
    "wrapperClass": "col-6",
    "isMulti": false,
    "id": "targetType",
    "inputType": "async-dropdown",
    // "url": "/ERActivity/GetActivities",
    "searchAttrib": "label",
    "labelAttribute": "label",
    "valueAttribute": "value",
    "onChangeMethod": "asyncdropdown",
    "isEnum": true
  },
  {
    "inputType": "button",
    "buttonType": "submit",
    "children": "Submit",
    "inForm": true,
    "wrapperClass": "mr-3 btn-wrapper position-right"
  }
]