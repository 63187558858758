import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Form from "../../components/form/Form";
import { clearForm, createRecord } from "../../redux/reducers";
import { getFieldsByCategory } from "../../utils/util";
import logo from "../../assets/logo.png";
import LoginTemplate from "../../components/login-signup/LoginTemplate";

const ForgetPassword = () => {
  const { pathname } = useLocation();

  const val = useSelector((state) => state.records?.response?.data);
  const { error } = useSelector((state) => state.records);

  const { formFields } = useSelector((state) => state.fields);
  const { response } = useSelector((state) => state.records);

  useEffect(() => {
    dispatch({ type: clearForm.type });
  }, [formFields, error]);

  const resetForm = [
    {
      category: "first",
      name: "Email",
      inputType: "email",
      label: "Email Address",
      placeholder: "Enter Email Address",
      type: "email",
      required: true,
      wrapperClass: "form-outline mb-2 col-12",
    },
    {
      category: "second",
      name: "newPassword",
      inputType: "password",
      label: "New Password",
      placeholder: "New Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-md-12",
      inForm: true,
    },
    {
      category: "second",
      name: "confirmPassword",
      inputType: "password",
      label: "Confirm Password",
      placeholder: "Confirm Password",
      required: true,
      type: "password",
      wrapperClass: "form-outline mb-2 col-md-12",
    },
    {
      category: "first",
      name: "continue",
      inputType: "button",
      buttonType: "submit",
      children: "Continue",
      wrapperClass: "ml-2 btn-wrapper col-md-12",
    },
    {
      category: "second",
      name: "resetPassword",
      inputType: "button",
      buttonType: "submit",
      children: "Reset Password",
      wrapperClass: "ml-2 btn-wrapper col-md-12",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.status == 200) navigate("/reset");
  }, [response]);

  const emailSubmit = (values) => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...values,
        url: "/Account/ForgotPassword",
      },
    });
  };

  const passwordSubmit = (values) => {
    const data = {
      email: { name: "email", value: val.email },
      token: { name: "token", value: val.token },
      ...values,
    };

    dispatch({
      type: createRecord.type,
      payload: {
        ...data,
        url: "/Account/RecoverPassword",
      },
    });

    !Object.keys(error).length == 0 && navigate("/login");
  };

  
  const meta = {
    headerTitle: "Forgot Password",
    headerDescription: "Enter your email to reset your password.",
    footerText: "Want to login instead?",
    footerLink: "/login",
    footerLinkText: "Login Instead",

  };
  return (
    // <LoginTemplate meta={meta} forgetpass>
      <div className="vh-100 d-flex justify-content-center align-items-center bg-white">
       
          <div className="card">
          <div className="card-body text-center">
            {pathname == "/forget" ? (
              <>
              <img
                src={logo}
                alt=""
                style={{  borderRadius: "10px" }}
                className="mb-3 px-4 py-2"
                width={200}
              />

                <Form
                  formData={getFieldsByCategory(resetForm, "first")}
                  onSubmit={emailSubmit}
                />
              </>
            ) : (
              <>
                <img
                  src="https://creazilla-store.fra1.digitaloceanspaces.com/cliparts/79024/lock-icon-clipart-md.png"
                  width={80}
                />
                <Form
                  formData={getFieldsByCategory(resetForm, "second")}
                  onSubmit={passwordSubmit}
                />
              </>
            )}

            {pathname !== "/forget" && (
              <button
                type="button"
                className="btn btn-outline-primary mt-3"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            )}

            <div className="d-flex justify-content-center mt-2">
            <Link to="/login" className="text-primary">
              Login
            </Link>
            </div>

           
          </div>
          </div>
       
      </div>
    // </LoginTemplate>
    // <>
    //     {/* <LoginTemplate meta={meta} forgetpass> */}
    //     <div className="vh-100 d-flex justify-content-center align-items-center bg-white">
    //         {pathname == "/forget" ? (
    //           <>
    //             <Form
    //               formData={getFieldsByCategory(resetForm, "first")}
    //               onSubmit={emailSubmit}
    //             />
    //           </>
    //         ) : (
    //           <div className=" col-12">

    //             <Form
    //               formData={getFieldsByCategory(resetForm, "second")}
    //               onSubmit={passwordSubmit}
    //             />
    //            <div className="text-center">
    //            <button
    //               type="button"
    //               className="btn btn-success mt-3"
    //               onClick={() => navigate(-1)}
    //             >
    //               Go Back
    //             </button>
    //            </div>


    //           </div>
    //         )}


    //       </div>
    //     {/* </LoginTemplate> */}
    //   </>
  );
};

export default ForgetPassword;
