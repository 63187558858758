import React, { useEffect } from "react";
import "./AgeandGenderView.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";

//   {
//     name: "Best 2023",
//     percentage: 25,
//   },
//   {
//     name: "Qurbani SMILE",
//     percentage: 45,
//   },
//   {
//     name: "WFP TPK",
//     percentage: 85,
//   },
//   {
//     name: "Childhood Project 2023",
//     percentage: 15,
//   },
//   {
//     name: "IDEAL Project 2021",
//     percentage: 90,
//   },
//   {
//     name: "Food and Nutrition",
//     percentage: 2,
//   },
// ];
const CurrentProjectsView = () => {
  const dispatch = useDispatch();
  const { projectPercentageData } = useSelector((state) => state.records.current);
  const projectsList = projectPercentageData?.data?.items ?? [];

  useEffect(() => {
    setTimeout(() => {
      getProjectData();
    }, 200)
  }, [])

  const getProjectData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetActivityPercentagePerProject?DisablePagination=true`,
        appendCurrentReducerKey: "projectPercentageData",
      },
    });
  }

  return (
    <div className="bg-white shadow-sm progressContainer h-100">
      <Label>Current Projects</Label>
      <div className="d-flex flex-column overflow-auto hide-scrollbar">
        {projectsList.map((data, index) => {
          return (
            <div className="d-flex flex-column mb-3" key={data?.id+index}>
              <div className="d-flex justify-content-between">
                <p class="lh-1 mb-1 heading">{data?.projectTitle}</p>
                <p class="lh-1 mb-1 heading">{parseFloat(data?.percentageOngoingOrCompleted).toFixed(0)}%</p>
              </div>
              <div
                class="progress"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="progress-bar"
                  style={{
                    width: `${data.percentageOngoingOrCompleted}%`,
                    background: "#84E0BE",
                  }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CurrentProjectsView;
