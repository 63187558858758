import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import usePageMeta from "../../hooks/usePageMeta";
import { getFormatedDate } from "../../Utility";

const MealProgressReport = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const { loading, data } = useSelector((state) => state.records);
  console.log("🚀 ~ MealProgressReport ~ data:", data)
  const dispatch = useDispatch();
  const {
    monitoring = "",
    evaluation = "",
    learning = "",
    accountability = "",
    programFacilitation = "",
    startDate = "",
    endDate = "",
  } = data;

  useEffect(() => {
    dispatch({
      type: "records/getRecords",
      payload: { url: `/MealProject/${id}` },
    });
  }, [id]);

  useEffect(() => {
    setMeta({ title: data?.project?.name });
  }, [data?.project]);

  return (
   
    <div className="mt-4">
    <Loader loading={loading} />
    <hr className="m-0" />
    <h5 className="text-primary mt-2">Details</h5>

    <div className="row mx-1 fieldTable border">
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4 fieldTitle">Start Date:</div>
          <div className="col-md-8">
            {getFormatedDate(startDate)}
          </div>
        </div>
      </div>


      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4 fieldTitle">End Date:</div>
          <div className="col-md-8">
            {getFormatedDate(endDate)}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4  fieldTitle">
            Village
          </div>
          <div className="col-md-8">
            {data?.village}
          </div>
        </div>
      </div>


      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4 fieldTitle">Monitoring:</div>
          <div className="col-md-8">
            {monitoring ?? ""}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4  w-fit fieldTitle">
          Evaluation <br/>Survey <br/>Assessment
          </div>
          <div className="col-md-8">
            {evaluation ?? ""}
          </div>
        </div>
      </div>


      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4 fieldTitle">
          Accountability
          </div>
          <div className="col-md-8">
            {accountability ?? ""}
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4 fieldTitle">
          Learning
          </div>
          <div className="col-md-8">
            {learning ?? ""}
          </div>
        </div>
      </div>


      <div className="col-md-6">
        <div className="row">
          <div className="col-md-4 fieldTitle">
          Program Facilitation
          </div>
          <div className="col-md-8">
            {programFacilitation ?? ""}
          </div>
        </div>
      </div>   
    </div> 
  </div>
  );
};

export default MealProgressReport;
