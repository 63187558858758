import React from "react";
import DashboardMainPage from "../dashboardTabPages/mainPage/DashboardMainPage";
import ActivityMapTab from "../dashboardTabPages/GeographicalData/ActivityMapTab";
import IndicatorMainTab from "../dashboardTabPages/Indicators/IndicatorsMainTab";
import MainComplaintTab from "../dashboardTabPages/Complaint/MainComplaintTab";
import MainActivitiesTab from "../dashboardTabPages/Activities/MainActivitiesTab";
import { useSelector } from "react-redux";

const DashboardTabPages = () => {
  const { currentActiveTab } = useSelector((state) => state?.setting?.filter);
  return (
    <div key={currentActiveTab}>
      {currentActiveTab === "Dashboard" && <DashboardMainPage />}
      {currentActiveTab === "Geographical Data" && <ActivityMapTab />}
      {currentActiveTab === "Indicators" && <IndicatorMainTab />}
      {currentActiveTab === "Activities" && <MainActivitiesTab />}
      {currentActiveTab === "Complaints" && <MainComplaintTab />}
    </div>
  );
};

export default DashboardTabPages;
