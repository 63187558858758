import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import "./Complaint.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getPieFormatedDataByArray } from "../../../utils/util";
import { getRecords } from "../../../redux/reducers";

const Activities = () => {
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const {activities} = useSelector((state) => state.records?.current);
const pieChartFormatData = getPieFormatedDataByArray(activities?.data?.items);

useEffect(()=>{
  dispatch({
    type: getRecords.type,
    payload: {
      url: `/ComplaintDashboard/GetActivitiesStats`,
      appendCurrentReducerKey: "activities",
    },
  });
},[]);
  // const activities = {
  //   values: [2, 25, 6, 4, 0, 0, 0, 0, 0],
  //   labels: [
  //     "Distribution",
  //     "Recruitment",
  //     "Procurement",
  //     "Training",
  //     "SEA Case",
  //     "Construction",
  //     "Payment",
  //     "Assessment",
  //     "Other",
  //   ],
  // };

 

  return (
    <div className="bg-white shadow-sm activities-container h-100 w-100 ">
      <Label>Activities</Label>
      <SimplePieChart
        type="donut"
        width={isMobileView ? 160 : "80%"}
        height={isMobileView ? 240 : "80%"}
        series={pieChartFormatData?.values ?? "Loading..."}
        labels={pieChartFormatData?.labels ?? "Loading..."}
        position={"right"}
        colors={["#F86624", "#662E9B", "#43BCCD", "#F9C80E","#09909F","#09909F","#09909F","#09909F","#09909F"]}
        //   options={{
        //     legend: {
        //       show: false,
        //     },
        //   }}
        // legendOptions={{
        //   width: "100%",
        //   height: "100%",
        //   itemMargin: {
        //     horizontal: 5,
        //     vertical: 5,
        //   },
        // }}
      />
    </div>
  );
};

export default Activities;
