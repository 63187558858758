import React from 'react';
import { pdf } from '@react-pdf/renderer';
import MonitoringVisitReportPDF from './MonitoringVisitReportPdf'; // Adjust the import based on your file structure

const OpenPDFInNewTab = ({ data=[] }) => {
  console.log("🚀 ~ OpenPDFInNewTab ~ data:", data)
  const handleOpenPDF = async () => {
    const blob = await pdf(<MonitoringVisitReportPDF data={data ?? []} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };

  return (
    <button onClick={handleOpenPDF} className="btn btn-danger ml-2">
      Download PDF
    </button>
  );
};

export default OpenPDFInNewTab;
