import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import Form from "../../components/form/Form";
import Loader from "../../components/loader/loader";
import {
  clearForm,
  createRecord,
  getRecords,
  putRecord,
  resetData,
  formatRecords
} from "../../redux/reducers";
import { getFieldsByCategory } from "../../utils/util";

const AddMealProgressReport = () => {

  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();
  const formFields = useSelector((state) => state.fields.formFields);
  const { loading, created, data } = useSelector((state) => state.records);
  const formValues = useSelector((state) => state.form.values);
  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    dispatch({
      type: "fields/fetchLayout",
      payload: "/json/meal-fields"
    });
  }, []);

  useEffect(() => {
    if (created) {
      navigate("/meal-progress-report");
    }
  }, [created]);

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/MealProject/${id}` }
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);
  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  const addRecord = () => {
    dispatch({
      type: createRecord.type,
      payload: { ...formValues, url: "/MealProject", headers: { 'Content-Type': 'multipart/form-data' } }
    });

  };

  function updateRecord() {
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: "/MealProject", headers: { 'Content-Type': 'multipart/form-data' } }
    });
  }

  return (
    <div className="mb-4">
      <Loader loading={loading} />
      <FormStepper
        initialStep={0}
        onSubmit={() => (canUpdate ? updateRecord() : addRecord())}
      >
        <Step key="projectDetail" title="Project Detail">
          <Form
            formData={getFieldsByCategory(formFields, "projectDetail")}
            withValidation={false}
          />
        </Step>
        <Step key="reportDetail" title="Report Detail">
          <div className="col-12">
            <Form
              formData={getFieldsByCategory(formFields, "reportDetail")}
              withValidation={false}
            />
          </div>
        </Step>
      </FormStepper>
    </div>
  );
};

export default AddMealProgressReport;
