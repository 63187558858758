import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import "./Header.scss";
import { saveUserState } from "../../utils/localStorage";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import Notifications from "../notification/Notifications";
import svgRepo from "../../assets/images/menu-svgrepo.svg";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, firstName = "" } = useSelector(
    (state) => state?.setting?.user?.userDetail
  );
  const { pageMeta } = useSelector((state) => state.setting);

  const items = useSelector(
    (state) => state.records?.current?.headerNotification?.data?.items
  );

  function handleChange({ value }) {
    if (value == "logout") {
      dispatch({ type: "setting/setUser", payload: {} });
      saveUserState({});
      navigate("/");
    } else {
      navigate(`user/${value}`);
    }
  }

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/GetAll`,
        params: { OrderDir: ORDER_DIRECTION.DESC },
        appendCurrentReducerKey: "headerNotification",
      },
    });
  };

  return (
    <div className="header row justify-content-between">
      <div className="col-md-6 col-12 flex">
        <img src={svgRepo} width={"32px"} height={"32px"} style={{marginRight : '8px'}} />
        <p className="text-capitalize header-title">{pageMeta?.title}</p>
        <p>{pageMeta?.breadcrumbs}</p>
      </div>

      {/* right side */}
      <div className="right col-md-3 col-12 text-right">
        <Notifications />
        <div className="dropdown">
          <img
            src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
            className="user-image"
            alt="user"
          />
          <select
            name="dropdown"
            className="p-1 form-control"
            id="dropdown"
            onChange={(evt) => handleChange(evt.target)}
          >
            <option value={"Detail"}>{firstName}</option>
            <option value={`${id}`} data-content="<Icon.PencilSquare />">
              Account
              <span>
                <Icon.PencilSquare />
              </span>
            </option>
            <option value={"changePassword"}>Change Password</option>
            <option value="logout">Logout</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Header;
