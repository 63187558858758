import Badge from "../../components/badge/Badge";
import { getFormatedDate } from "../../Utility";

const SmartIndicatorColumns = [
  {
    name: "Specific Objective",
    selector: (row) => row.specificObjective?.name,
    maxWidth: "150px"
  },
  // {
  //   name: "Logic Type",
  //   selector: (row) => row.interventionLogicType,
  // },
  {
    name: "Definition",
    selector: (row) => row.definition,
    maxWidth: "150px"
  },
  {
    name: "Indicator Target",
    selector: (row) => row.indicatorTarget
  },
  {
    name: "Baseline Value",
    selector: (row) => row.baselineValue
  },
  {
    name: "Means of Verification",
    selector: (row) => (
      <>
        {row.siMeanOfVerifications &&
          row.siMeanOfVerifications.map(({ name = "" }, index) => (
            <Badge className="m-1 text-left btn btn-sm btn-info" key={index}>
              {name}
            </Badge>
          ))}
      </>
    ),
    wrap: true,
    maxWidth: "150px"
  },
  {
    name: "Periodicity",
    selector: (row) => row.periodicity?.name
  },
  {
    name: "Responsible Person",
    selector: (row) => row.responsiblePerson?.name
  },
  {
    name: "Indicator Achievement Date",
    selector: (row) => getFormatedDate(row.indicatorAchievementDate)
  },
  {
    name: "Assumptions/Risks",
    selector: (row) => (
      <>
        {row.siAssumptionOrRisks &&
          row.siAssumptionOrRisks.map(({ name = "" }, index) => (
            <Badge
              className="m-1 text-left btn btn-sm btn-secondary"
              key={index}
            >
              {name}
            </Badge>
          ))}
      </>
    ),
    wrap: true,
    maxWidth: "150px"
  }
];

export default SmartIndicatorColumns;
