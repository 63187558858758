import { Link } from "react-router-dom";
import { getFormatedDate, getStatusStyle } from "../../Utility";

const ComplaintColumns = [
  {
    name: "Complainant Name",
    selector: (row) => (
      <>
        <Link to={`${row?.id}`} className="text-capitalize">
          {row?.complainantName}
        </Link>
      </>
    ),
  },
  {
    name: "Phone No.",
    selector: (row) => row?.cellNo,
  },
  {
    name: "cnic",
    selector: (row) => row?.cnic,
  },
  // {
  //   name: "Complaint Desc",
  //   selector: (row) => row?.descriptionOfComplaint
  // },
  {
    name: "Complaint Type",
    selector: (row) => row?.typeOfComplainant?.name,
  },
  {
    name: "Complaint Nature",
    selector: (row) => row?.natureOfComplaint?.name,
  },
  {
    name: "Complaint Mode",
    selector: (row) => row?.modeOfComplaint?.name,
  },
  {
    name: "Turn Around Time",
    selector: (row) => getFormatedDate(row?.dateOfComplaint),
  },
  {
    name: "Complaint Status",
    selector: (row) => (
      <label className={getStatusStyle(row?.statusOfComplaint)}>
        {row?.statusOfComplaint}
      </label>
    ),
  },
];

export default ComplaintColumns;
