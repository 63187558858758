import { put } from "redux-saga/effects";
import axios from "./api-client";
import { setErrors, setUserMessages, clearForm } from "../reducers";
import { baseUrl } from "..";
import { formatPostFields, formatByHeader } from "./sagas.helper";

export function* putRecordAsync(action) {
  const { url, headers = {}, ...payload } = action.payload;
  const fullUrl = baseUrl + '/api' + url;
  if (url) {
    try {
      let formatedData = formatPostFields(payload);
      formatedData = formatByHeader(formatedData, headers);

      const data = yield axios
        .put(fullUrl, formatedData, { headers })
        .then(({ data }) => data);
      yield put({
        type: setUserMessages.type,
        payload: { message: "Record Updated Successfully!", updated: true, ...action.payload },
      });
      // yield put({
      //   type: clearForm.type
      // });
    } catch (e) {
      yield put({
        type: setUserMessages.type,
        payload: { ...action.payload, ...e },
      });
      yield put({
        type: setErrors.type,
        payload: { errorIn: action.type, message: e.message },
      });
    }
  } else {
    yield put({
      type: setErrors.type,
      payload: { errorIn: action.type, message: "No url specified!" },
    });
    yield put({
      type: setUserMessages.type,
      payload: { ...action.payload, error: 'TypeError', errorIn: action.type },
    });
  }
}
