// import React, { useState, useEffect } from "react";

// import "./charts.scss";
// // import canvas from "./../../assets/images/sdg-chart-canvas.png"
// import { ReactComponent as SvgChart } from "./../../assets/images/sdg_fin.svg";
// import { useDispatch, useSelector } from "react-redux";
// import { getRecords } from "../../redux/reducers";

// const SDGChart = ({ projectId }) => {
//   const dispatch = useDispatch();
//   const [state, setState] = useState({ selectedId: 1, selectedSDG: null });
//   const { sdgChartData } = useSelector((state) => state.records.current);
//   console.log("🚀 ~ SDGChart ~ sdgChartData:", sdgChartData)
//   const data = sdgChartData ? sdgChartData?.data?.items : [];

//   useEffect(() => {
//     setTimeout(() => {
//       dispatch({
//         type: getRecords.type,
//         payload: {
//           url: projectId
//             ? `/Dashboard/GetSDGProjectCount?Project.Id=${projectId}`
//             : "/Dashboard/GetSDGProjectCount",
//           appendCurrentReducerKey: "sdgChartData",
//         },
//       });
//     }, 1000);
//   }, [projectId]);

//   const getIdFromName = (name) => {
//     if (!name) return null;
//     return name.match(/\d+|[^\d_.-]+/g)[1];
//   };

//   const showContentById = (contentId) => {
//     const selectedId = getIdFromName(contentId);
//     if (!selectedId) return;
//     const selectedSDG = data?.find(({ sdgNo }) => sdgNo == parseInt(selectedId));
//     setState({ ...state, selectedId, selectedSDG });
//   };

//   const getEmptySdgs = () => {
//     const emptySdgs =
//       data &&
//       data
//         .filter(({ count }) => count === 0)
//         .map(({ sdgNo = 1 }) => `sdg-${sdgNo}`);
//     return emptySdgs?.join(" ");
//   };

//   const onClose = () => {
//     setState({ ...state, selectedSDG: null });
//   };
//   return (
//     <>
//       <div className="sdg-wrapper">
//         <SvgChart
//           className={getEmptySdgs()}
//           onClick={(evt) => showContentById(evt.target.parentNode.id)}
//         />
//         {state?.selectedSDG && (
//           <div
//             className="circle-pop intro"
//             style={
//               state?.selectedSDG?.count > 0
//                 ? { backgroundColor: `#${state?.selectedSDG?.colorCode}` }
//                 : {}
//             }
//           >
//             <span onClick={() => onClose()} className="close">
//               X
//             </span>
//             <div className="circle-content">
//               <div className="text-content text-center w-100">
//                 <p className="heading">{state?.selectedSDG?.name}</p>
//                 <p className="totalCalculate">
//                   Total Projects: {state?.selectedSDG?.count}
//                 </p>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default SDGChart;
import React, { useState, useEffect } from "react";
import "./charts.scss";
import { ReactComponent as SvgChart } from "./../../assets/images/sdg_fin.svg";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";

const SDGChart = ({ projectId }) => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(1);
  const [selectedSDG, setSelectedSDG] = useState(null);
  // console.log("🚀 ~ file: SDGChart.js:99 ~ SDGChart ~ selectedSDG:", selectedSDG);
  const { sdgChartData } = useSelector((state) => state.records.current);
  const data = sdgChartData?.data?.items || [];

  useEffect(() => {
    const fetchData = () => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: projectId
            ? `/Dashboard/GetSDGProjectCount?Project.Id=${projectId}`
            : "/Dashboard/GetSDGProjectCount",
          appendCurrentReducerKey: "sdgChartData",
        },
      });
    };
    setTimeout(fetchData, 1000);
  }, [projectId, dispatch]);

  const getIdFromName = (name) => {
    if (!name) return null;
    const match = name.match(/\d+|[^\d_.-]+/g);
    return match ? match[1] : null;
  };

  const handleChartClick = (event) => {
    console.log("🚀 ~ handleChartClick ~ event:", event.target.parentNode.id)
    const contentId = event.target.parentNode.id;
    const id = getIdFromName(contentId);
    if (id) {
      const sdg = data.find(({ sdgNo }) => sdgNo === parseInt(id));
      setSelectedId(id);
      setSelectedSDG(sdg);
      if (!sdg) {
        setSelectedSDG({ id: 0, sdgNo: id, colorCode: 'gray', name: "No Data Found", count: 0 });
      }
    }
  };

  const getEmptySdgs = () => {
    return data
      .filter(({ count }) => count === 0)
      .map(({ sdgNo }) => `sdg-${sdgNo}`)
      .join(" ");
  };

  const handleClose = () => {
    setSelectedSDG(null);
  };

  const getDisabledSdg = () => {
    const disabledSdg = [];
    const counts = 17;
    if (data) {
      for (let i = 1; i <= counts; i++) {
        const sdgNo = data.some(({ sdgNo }) => sdgNo == i);
        if (!sdgNo) {
          disabledSdg.push(`#sdg-${i} path{fill:gray}`);
        }
      }
    }
    return disabledSdg.join(" ");
  }

  return (
    <div className="sdg-wrapper">
      <style>
        {getDisabledSdg()}
      </style>
      <SvgChart
        className={getEmptySdgs()}
        onClick={handleChartClick}
      />
      {selectedSDG && (
        <div
          className="circle-pop intro"
          style={selectedSDG?.count > 0 ? { background: `#${(selectedSDG?.colorCode).trim()}` } : {}}
        >
          <span onClick={handleClose} className="close text-center">X</span>
          <div className="circle-content">
            <div className="text-content text-center w-100">
              <p className="heading">{selectedSDG.name}</p>
              <p className="totalCalculate">Total Projects: {selectedSDG.count}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SDGChart;
