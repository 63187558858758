import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { getLayoutAsync,getLookupAsync,getRecordsAsync } from "./get";
import { getRecords, resetData, setUserMessages, formatRecords, createRecord, putRecord } from "../reducers";
import { postRecordAsync } from "./post";
import { putRecordAsync } from "./put";
import { branchedActionAsync, formatRecordsAsync } from "./general";
import { deleteRecordAsync } from "./delete";
import { deleteRecord } from "../reducers/recordSlice";

export function* resetDataAsync(action) {
  yield put(resetData(action.payload));
}
export function* watcherSaga() {
  yield takeEvery(getRecords.type, getRecordsAsync);
  yield takeLatest(formatRecords.type, formatRecordsAsync);
  yield takeLatest(createRecord.type, postRecordAsync);
  yield takeEvery("fields/fetchLayout", getLayoutAsync);
  yield takeEvery("form/fetchLookup", getLookupAsync);
  yield takeLatest(putRecord.type, putRecordAsync);
  yield takeLatest(deleteRecord.type, deleteRecordAsync);
  yield takeEvery(setUserMessages.type, branchedActionAsync);
}
