import React, { useEffect } from "react";
import "./Complaint.scss";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getPieFormatedDataByArray } from "../../../utils/util";
import { getRecords } from "../../../redux/reducers";

const projectsList = [
  {
    name: "Project 1",
    percentage: 25,
  },
  {
    name: "Project 2",
    percentage: 45,
  },
  {
    name: "Project 3",
    percentage: 85,
  },
  {
    name: "Project 4",
    percentage: 15,
  },
  {
    name: "Project 5",
    percentage: 90,
  },
  {
    name: "Project 6",
    percentage: 2,
  },
  {
    name: "Project 7",
    percentage: 100,
  },
  {
    name: "Project 8",
    percentage: 60,
  },
  {
    name: "Project 9",
    percentage: 70,
  },
];
const ProjectsListForComplaint = () => {

  const dispatch = useDispatch();
  const {projectListData} = useSelector((state) => state.records?.current);
  console.log("🚀 ~ ProjectsListForComplaint ~ projectListData:", projectListData?.data)

useEffect(()=>{
  dispatch({
    type: getRecords.type,
    payload: {
      url: `/ComplaintDashboard/GetProjectStats`,
      appendCurrentReducerKey: "projectListData",
    },
  });
},[]);

  return (
    <div className="bg-white shadow-sm project-list-container h-100 overflow-auto">
      <div className='mb-4'>
        <Label>Projects</Label>
      </div>
      {projectListData?.data?.items?.map((data, index) => {
        return (
          <div key={index.toString()} className="d-flex flex-column mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <p class="lh-1 mb-0 heading">{data?.project ?? "Loading..."}</p>
              <div
                class="progress w-75"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="progress-bar"
                  style={{
                    width: `${data?.percentage ?? 0}`,
                    background: parseFloat(data?.percentage) > 50 ? "#84E0BE" : "#0075B6",
                    borderRadius: "10px",
                  }}
                ></div>
              </div>
              <p class="lh-2 ml-1 mb-0 heading">{data?.percentage}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectsListForComplaint;
