import React from "react";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStepper } from "../../redux/reducers";
import { validateForm } from "../form/form.helper";

import "./FormStepper.scss";

export const FormStepper = ({ children = [], onNext, initialStep = 0, onSubmit, fields = [], data = [] }) => {
  const { stepper } = useSelector((state) => state.setting);
  const { submitted = false, currentStep } = stepper;
  const dispatch = useDispatch();


  useEffect(() => {
    onCountUpdate(initialStep)
  }, [])

  useEffect(() => {
    if (submitted)
      onSubmit()
    return () => {
      dispatch({ type: setStepper.type, payload: { submitted: false } })
    }
  }, [submitted])


  function onCountUpdate(value) {
    // setCount(value)
    let isValid = true;
    if (data && fields && Object.keys(data).length > 0)
      isValid = validateForm(fields, data, dispatch);
    if (isValid) {
      dispatch({ type: setStepper.type, payload: { currentStep: value, totalSteps: children.length } })
      dispatch({ type: "form/setValidations", payload: {} });
    }
  }

  return (
    <div className="form-stepper">
      <StepperNav children={children} count={currentStep} />
      <Step children={children} count={currentStep} />
      <StepperAction count={currentStep} children={children} setCount={(value) => onCountUpdate(value)} onNext={onNext} />
    </div>
  );
};

export const Step = ({ children, count }) => {
  return <div className="stepper-content">
    {children.length && count <= children.length ? children[count]?.props.children : ""}
  </div>
}

export const StepperNav = ({ children, count, ...otherProps }) => {
  return <section className="step-wizard">
    <ul className="step-wizard-list">
      {children.map(({ props }, j) => {
        return (
          <li
            key={j}
            className={`step-wizard-item ${count == j ? "current-item" : ""
              }`}
          >
            <span className="progress-count">{j + 1}</span>
            <span className="progress-label">{props?.title}</span>
          </li>
        );
      })}
    </ul>
  </section>
}

export const StepperAction = ({ count, children, setCount, onNext }) => {
  const navigate = useNavigate()
  const onCancel = () => {
    confirmAlert({
      message: "Are you sure to Go Back",
      buttons: [
        {
          label: "Yes",
          onClick: () => navigate(-1),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypressEscape: () => { },
      overlayClassName: "overlay-custom-class-name",
    });
  };

  return <div className="stepper-action">
    {count == 0 ? <button
      className="btn-primary btn-outline"
      onClick={() => onCancel()}
    >
      Cancel
    </button> : <button
      className="btn-primary btn-outline"
      onClick={() => setCount(count - 1)}
    >
      Back
    </button>}

    <button
      disabled={count >= children.length - 1}
      onClick={() => { onNext ? onNext() : setCount(count + 1) }}
    >
      Next
    </button>

    {count >= children.length - 1 && <button
      onClick={() => { onNext ? onNext() : setCount(count + 1) }}
    >
      Submit
    </button>}
  </div>
}
