import { isRowSelected } from "@tanstack/react-table";
import { Link } from "react-router-dom";

const NotificationColumns = [
  {
    name: "Subject",
    selector: (row) =>
      row.entityType === "Complaint" ? (
        <Link to={`/complaints/${row?.entityId}`}>
          <label htmlFor="" className="text-primary cursor-pointer">
            {row.subject}
          </label>
        </Link>
      ) : (
        row.subject
      ),
  },
  {
    name: "Message",
    selector: (row) => row.message,
  },
  {
    name: "Entity Type",
    selector: (row) => row.entityType,
  }
];

export default NotificationColumns;
