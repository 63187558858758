import ProgressBar from "../../components/progress-bar/ProgressBar";
import { getActivityStatusStyle } from "./../../Utility";
export const ActivityPageColumns = [
  {
    name: "Activity & Tasks",
    selector: (row) => <div className="text-capitalize">{row?.soName}</div>,
  },
  {
    name: "Target",
    selector: (row) => row?.target,
  },
  {
    name: "Activity Output",
    selector: (row) => row?.activity_output,
  },
  {
    name: "%Execution",
    selector: (row) => row?.execution,
  },
  {
    name: "Status",
    selector: (row) => (
      <></>
      // <label className={getStatusStyle(row?.status)}>
      //   {row?.status.charAt(0).toUpperCase() + row?.status.slice(1)}
      // </label>
    ),
  },
];

export const SpecificObjectiveColumn = [
  {
    name: "Activity & Tasks",
    selector: (row) => <div className="text-capitalize">{row?.soName}</div>,
  },
];
export const ExpectedResultColumns = [
  {
    name: "Expected Results",
    selector: (row) => row?.erName,
  },
];
export const ERActivityColumns = [
  {
    name: "Activity",
    selector: (row) => row?.name,
  },
];

export const SubActivityColumns = [
  {
    name: "Sub Activity",
    selector: (row) => row?.name,
  },
  {
    name: "Target",
    selector: (row) => row?.target,
  },
  {
    name: "Activity Output",
    selector: (row) => row?.activityOutput?.name,
  },
  {
    name: "Executed",
    selector: (row) => <ProgressBar value={row?.executedPercentage} />,
  },
  {
    name: "Status",
    selector: (row) => (
      <label className={getActivityStatusStyle(row?.status)}>
        {row?.status}
      </label>
    ),
  },
  {
    name: "Comments",
    selector: (row) => row?.comments,
  },
];
