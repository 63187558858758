import { createSlice } from "@reduxjs/toolkit";
import { loadUserState } from "../../utils/localStorage";
import { ACTIVE_TAB } from "../../Constants";
const userState = loadUserState();
console.log("🚀 ~ file: settingSlice.js:5 ~ userState:", userState);
const initialState = {
  drawer: false,
  module: "financial-management",
  organizationName: "My Organization",
  username: "Demo User",
  searchFields: [],
  formFields: [],
  userMessages: [],
  stepper: { currentStep: 0, submitted: false },
  // user: userState ? { ...userState, userDetail: { roles: [] } } : { token: null, userDetail: { roles: [] } },
  user: userState ? userState : { token: null },
  pageMeta: { title: "SIF" },
  filter: {
    currentActiveTab: ACTIVE_TAB.Dashboard,
  },
};
const getModuleName = (path) => {
  if (path.includes("financial-management")) {
    return "financial-management";
  }
};
export const userSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    getSetting() { },
    draw(state, action) {
      return {
        ...state,
        drawer: !state.drawer,
      };
    },
    setUserMessages(state, action) {
      return {
        ...state,
        userMessages: [...state.userMessages, action.payload],
      };
    },
    setUser(state, action) {
      return {
        ...state,
        user: { ...action.payload },
      };
    },
    closeModel(state, action) {
      return {
        ...state,
        userMessages: state.userMessages.filter((el, i) => i != action.payload),
      };
    },
    setStepper(state, action) {
      let overridePayload = { ...action.payload, submitted: false };
      if (
        !action.payload?.submitted &&
        state.stepper.totalSteps - 1 < action.payload.currentStep
      ) {
        overridePayload = {
          ...overridePayload,
          submitted: true,
          currentStep: state.stepper.currentStep,
        };
      }
      return {
        ...state,
        stepper: { ...state.stepper, ...overridePayload },
      };
    },
    setPageMeta(state, action) {
      return {
        ...state,
        pageMeta: { ...state.pageMeta, ...action.payload },
      };
    },

    setFilter(state, action) {
      return {
        ...state,
        filter: { ...state.filter, ...action.payload },
      };
    },
  },
});

export const {
  getSetting,
  setUserMessages,
  closeModel,
  setStepper,
  setUser,
  setPageMeta,
  setFilter,
} = userSlice.actions;
// Action creators are generated for each case reducer function

export default userSlice.reducer;
