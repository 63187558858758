import React, { useEffect } from "react";
import "./DashboardMainPage.scss";
import TopInfoView from "./TopInfoView";
import AgeandGenderView from "./AgeandGenderView";
import CurrentProjectsView from "./CurrentProjectsView";
import SDGChart from "../../chart/SDGChart";
import MapChart from "./MapChart";
import ReacentActivitiesGallery from "./ReacentActivitiesGallery";
import ProjectMVRStatus from "./ProjectMVRStatus";
import ActionPlanStatus from "./ActionPlanStatus";
import FundsDonarDistribution from "./FundsDonarDistribution";
import WeeklyReportTracker from "./WeeklyReportTracker";
import SectorWiseProgress from "./SectorWiseProgress";
import OverlappingBeneficiaries from "./OverlappingBeneficiaries";
import ComplaintStatus from "./ComplaintStatus";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import {
  getPieFormatedData,
  getBarChartFormattedDate,
} from "../../../utils/util";
import GenderAgeSegreBenficiaries from "./GenderAgeSegreBenficiaries";
import TargetVsAchievedBenif from "./TargetVsAchievedBenif";

const DashboardMainPage = () => {
  const dispatch = useDispatch();
  const { activitiesCount = "", projectList } = useSelector(
    (state) => state.records.current
  );

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200);
  }, []);



  const getData = () => {

  
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByActivities`,
        appendCurrentReducerKey: "activitiesCount",
      },
    });
  };

  return (
    <>
      <TopInfoView/>
      <div class="container-fluid p-0 my-4">
        <div class="row px-0 g-3 row-container">
          <div class="col-xl-8 margin">
            <AgeandGenderView />
          </div>
          <div class="col-xl-4">
            <CurrentProjectsView />
          </div>
        </div>

        <div class="row px-0 g-3 mt-4 row-container">
          {/* <div class="col-xl-4 margin">
            <MapChart />
          </div> */}
          <div class="col-xl-4 margin">
            <div className="h-100 bg-white itemContainerLast shadow-sm">
              <Label>SDG Wise Distribution</Label>
              <SDGChart />
            </div>
          </div>
          <div class="col-xl-4 margin">
            <OverlappingBeneficiaries />
          </div>
          <div class="col-xl-4 margin">
            <ReacentActivitiesGallery />
          </div>
        </div>

        <div class="row px-0 g-3 mt-4 row-container">
          <div class="col-xl-8 margin">
            <ProjectMVRStatus />
          </div>
          <div class="col-xl-4 margin">
            <SectorWiseProgress />
          </div>
          {/* <div class="col-xl-4">
            <ActionPlanStatus />
          </div> */}
        </div>
      </div>
      <div class="row px-0 g-3 mt-4 row-container">
        <div class="col-xl-6 margin">
          <FundsDonarDistribution />
        </div>
        <div class="col-xl-6 margin">
          {/* <GenderAgeSegreBenficiaries /> */}
          <TargetVsAchievedBenif />
        </div>
        {/* <div class="col-xl-4">
          <WeeklyReportTracker />
        </div> */}
      </div>
      <div class="row px-0 g-3 mt-4 row-container">
        {/* <div class="col-xl-4 margin">
          <OverlappingBeneficiaries />
        </div> */}
        {/* <div class="col-xl-4">
          <ComplaintStatus />
        </div> */}
      </div>
    </>
  );
};

export default DashboardMainPage;
