import React from "react";
import { deleteRecord } from "../redux/reducers/recordSlice";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import * as Icon from "react-bootstrap-icons";
import ToolTip from "../components/tooltip/Tooltip";

const DeleteRow = ({ row, url }) => {
  const dispatch = useDispatch();
  const onDelete = (id) => {
    confirmAlert({
      message: "Are you sure to delete this ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    });
  };
  const handleDelete = (id) => {
    dispatch({
      type: deleteRecord.type,
      payload: { id, url: url },
    });
  };
  return (
    <>
      <ToolTip label="Delete">
        <Icon.Trash
          size={18}
          onClick={() => onDelete(row?.id)}
          className="action-icons"
        />
      </ToolTip>
    </>
  );
};

export default DeleteRow;
