export const COMPLAINT_STATUS = {
  PENDING: "pending",
  CLOSED: "closed",
  APPROVE: "approve",
  TRUE: "true",
  FALSE: "false",
};

export const INTERVENTION_LOGIC_TYPE = {
  SPECIFIC_OBJECTIVE: "SpecificObjective",
  EXPECTED_RESULT: "ExpectedResult",
};

export const ACTIVITY_STATUS_TYPE = {
  IN_Progress: "InProgress",
  PENDING: "Pending",
  COMPLETED: "Completed",
};

export const ORDER_DIRECTION = {
  ASC: "Asc",
  DESC: "Desc",
};

export const COMPLAINT_STATUS_TYPE = {
  PENDING: "Pending",
  CLOSED: "Closed",
};

export const COMPLAINT_LEVEL_SATISFACTION = {
  SATISFIED: "Satisfied",
  NEUTRAL: "Neutral",
  DISSATISFIED: "Dissatisfied",
};

export const MODE_OF_RESPONSE = {
  TELEPHONICALLY: "Telephonically",
  MEETING: "Meeting",
  OTHERS: "Others",
};

export const USER_ROLE = {
  ADMIN: "Admin",
  SUPER_ADMIN: "Super_admin",
  BASE_PROJECT_MANAGER: "BaseProjectManager",
  PROJECT_MANAGER: "ProjectManager",
  COUNTRY_MANAGER: "CountryManager",
  BASE_COUNTRY_MANAGER: "BaseCountryManager",
  MANAGEMENT: "Management",
  MANAGER: "Manager",
  OFFICER: "Officer",
  ASSITANT: "Assistant",
};

export const PROJECT_STATUS = {
  ON_GOING: "Ongoing",
  COMPLETED: "Completed",
};

export const ACTIVE_TAB = {
  Dashboard: "Dashboard",
  "Geographical Data": "Geographical Data",
  Indicators: "Indicators",
  Activities: "Activities",
  Complaints: "Complaints",
};
