import { COMPLAINT_LEVEL_SATISFACTION } from "../../Constants";

const complaintLevelSatisfaction = [
    {
        label: COMPLAINT_LEVEL_SATISFACTION.SATISFIED,
        value: COMPLAINT_LEVEL_SATISFACTION.SATISFIED
    },
    {
        label: COMPLAINT_LEVEL_SATISFACTION.NEUTRAL,
        value: COMPLAINT_LEVEL_SATISFACTION.NEUTRAL
    },
    {
        label: COMPLAINT_LEVEL_SATISFACTION.DISSATISFIED,
        value: COMPLAINT_LEVEL_SATISFACTION.DISSATISFIED
    }
]
export default complaintLevelSatisfaction;