import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import { useEffect } from "react";

import Loader from "../../components/loader/loader";
import { debriefFields } from "../../assets/fields/debriefFields";
import Form from "../../components/form/Form";
import {
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
} from "../../redux/reducers";
import usePageMeta from "../../hooks/usePageMeta";
import Table from "../../components/table/Table";
import DebriefColumns from "../../assets/columns/DeBriefColumns";
import { ORDER_DIRECTION } from "../../Constants";
import { getFormatedDate } from "../../Utility";
import { baseUrl } from "../../redux";
import ToolTip from "../../components/tooltip/Tooltip";
import DeleteRow from "../DeleteRow";
import OpenPDFInNewTab from "./OpenPDFInNewTab";
import "../projects/Projects.scss";
import "./MonitoringVisitReport.scss";
import { truncateText } from "../../utils/util";

const MonitoringVisitReport = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [debriefId, setDebriefId] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { setMeta } = usePageMeta();
  const { loading, data, current, created } = useSelector(
    (state) => state.records
  );


  const items = current?.monitoringDebrief?.data?.items;
 
  const debriefData = current?.debriefById?.data;
  const keyActivitiesColumns = [
    {
      name: "s.No.",
      selector: (row) => row?.id,
    },
    {
      name: "Activity Name",
      selector: (row) => 
        <ToolTip label={`${row?.activity?.name}`}>
          {truncateText(row?.activity?.name ?? "", 20)}
        </ToolTip>
    },
    {
      name: "Planned Target",
      selector: (row) => row?.plannedTarget,
    },
    {
      name: "Achevied",
      selector: (row) => row?.achievedTarget,
    },
    {
      name: "Remarks",
      selector: (row) =>
        <ToolTip label={`${row?.remarks}`}>
          {truncateText(row?.remarks ?? "", 20)}
        </ToolTip>
    },
  ];

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: { url: `/MonitoringVisitReport/${id}` },
    });

    setTimeout(() => {
      getAllDebriefs();
    }, 200);
  }, [id]);

  useEffect(() => {
    if (created) {
      setIsOpen(false);
      getAllDebriefs();
    }
  }, [created]);

  useEffect(() => {
    setMeta({ title: data?.project?.name });
  }, [data?.project]);

  function getAllDebriefs() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/MonitoringDeBrief/GetAll`,
        params: {
          MonitoringReportId: id,
          OrderDir: ORDER_DIRECTION.DESC,
          DisablePagination: false,
        },
        appendCurrentReducerKey: "monitoringDebrief",
      },
    });
  }

  function postData(params) {
    debriefId
      ? dispatch({
        type: putRecord.type,
        payload: {
          url: `/MonitoringDeBrief`,
          ...params,
        },
      })
      : dispatch({
        type: createRecord.type,
        payload: {
          url: "/MonitoringDeBrief",
          ...params,
          monitoringVisitReport: { id },
        },
      });
  }

  function toggleActivityModal() {
    setIsOpen(!modalIsOpen);
  }

  const formatedFields = (fields) => {
    return fields.map((field) => {
      if (field?.url)
        return { ...field, url: `${field.url}?MonitoringReportId=${id}` };
      return { ...field };
    });
  };

  const debriefActionCol = [
    ...DebriefColumns,

    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Delete">
            <Icon.PencilSquare
              className="action-icons"
              onClick={() => toggleDebriefModel(row?.id)}
            />
          </ToolTip>
          <DeleteRow row={row} url="/MonitoringDeBrief" />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...debriefData } });
    }
  }, [debriefData]);

  const toggleDebriefModel = (id) => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/MonitoringDeBrief/${id}`,
        appendCurrentReducerKey: "debriefById",
      },
    });
    setDebriefId(id);
    setIsOpen(true);
  };
  return (
    <>
      <div className="mt-4">
        <Loader loading={loading} />
        <div className="d-flex justify-content-between">
          <h5 className="mb-4">Monitoring Visit Report</h5>
          <div className="button">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setIsOpen(!modalIsOpen)}
            >
              Add Debrief
            </button>
            <OpenPDFInNewTab data={data} />
          </div>
        </div>
        <hr className="m-0" />
        <h5 className="text-primary mt-2">Details</h5>

        <div className="row mx-1 fieldTable border">

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">Project:</div>
              <div className="col-md-8">
                {data?.project?.name}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">Start Date:</div>
              <div className="col-md-8">
                {getFormatedDate(data?.projectStartDate)}
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">End Date:</div>
              <div className="col-md-8">
                {getFormatedDate(data?.projectEndDate)}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4  fieldTitle">
                Reporting Person Name:
              </div>
              <div className="col-md-8">
                {data?.reportingPerson?.name}
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Date of Monitoring Visit:
              </div>
              <div className="col-md-8">
                {getFormatedDate(data?.dateOfMonitoring)}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4  fieldTitle">
                Location of Visit:
              </div>
              <div className="col-md-8">
                {data?.locationOfVisit}
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">Visit Objectives:</div>
              <div className="col-md-8">
                {data?.visitObjectives}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4  fieldTitle">
                Methodology adopted:
              </div>
              <div className="col-md-8">
                {data?.methodologyAdopted}
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Project Result/Output:
              </div>
              <div className="col-md-8">
                {data?.projectResult}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Methodology adopted:
              </div>
              <div className="col-md-8">
                {data?.methodologyAdopted}
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Project Result/Output:
              </div>
              <div className="col-md-8">
                {data?.projectResult}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Methodology adopted:
              </div>
              <div className="col-md-8">
                {data?.methodologyAdopted}
              </div>
            </div>
          </div>


          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Key Observations:
              </div>
              <div className="col-md-8">
                {data?.keyObservation}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Best Practices in Ongoing Activities:
              </div>
              <div className="col-md-8">
                {data?.bestPractices}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Key Challenges/Risk:
              </div>
              <div className="col-md-8">{data?.keyChallenges}</div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4 fieldTitle">
                Recommendations/ Suggestions:
              </div>
              <div className="col-md-8">{data?.recommendations}</div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="col-md-2 fieldTitle">Attachments:</div>
              <div className="col-md-10">
                <div className="d-flex flex-wrap">
                  {data?.attachments && data?.attachments?.length > 0 ? (
                    data?.attachments.map((img, index) => (
                      <div key={index} className="mr-2">
                        {img?.url && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={baseUrl + img?.url}
                          >
                            <img
                              style={{
                                height: "80px",
                                width: "80px",
                                objectFit: "cover",
                              }}
                              src={baseUrl + img?.url}
                              alt="image"
                            />
                          </a>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No Images Added</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <h5 className="text-primary mt-4">Key Activities</h5>
        <div className="my-3">
          <Table columns={keyActivitiesColumns} data={data?.keyActivities ?? []} />
        </div>

        <div>
          <div className="d-flex justify-content-between">
            <h5 className="text-primary">DeBrief</h5>
            <div className="button">
              <ToolTip label="Add Debrief">
                <Icon.PlusCircle
                  className="text-primary mr-2 cursor-pointer"
                  onClick={() => setIsOpen(!modalIsOpen)}
                />
              </ToolTip>
            </div>
          </div>

          <Loader loading={loading} />
          <div className="my-3">
          <Table columns={debriefActionCol} data={items ? items : []} />
          </div>
        </div>

        {/* Modal */}
        <Modal
          isOpen={modalIsOpen}
          ariaHideApp={false}
          overlayClassName="modal-sm smart-indicator-modal"
          contentLabel="Smart Indicator"
        >
          <Icon.XCircle onClick={toggleActivityModal} className="action-icons" />
          <h4>Add Debrief</h4>
          <Form
            formData={formatedFields(debriefFields)}
            onSubmit={(params) => postData(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Modal>
      </div>

    </>

  );
};

export default MonitoringVisitReport;
