import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { formatRecords } from "../../redux/reducers";

import Form from "../form/Form";
import FundingDetails from "../funding-details/FundingDetails";
import Loader from "../loader/loader";

const ProjectFunding = ({ projectId }) => {
    const dispatch = useDispatch();
    const { loading, data } = useSelector((state) => state.records);
    const { formFields } = useSelector((state) => state.fields);

    //get form fields
    useEffect(() => {
        dispatch({ type: 'fields/fetchLayout', payload: '/json/project-funding-detail-fields' })
    }, [])

    //update project actions
    useEffect(() => {
        if (projectId)
            dispatch({ type: "records/getRecords", payload: { url: `/project/${projectId}` } })
        return () => {
            // dispatch({ type: resetData.type })
            // dispatch({ type: 'form/clearForm' })
        }
    }, [projectId])

    useEffect(() => {
        if (data) {
            mapFormFields()
        }
    }, [data])

    function mapFormFields() {
        dispatch({ type: formatRecords.type, payload: { ...data } })
    }

    function getFieldsByCategory(fields = [], key) {
        return fields.filter(({ category = '' }) => category == key)
    }

    return (
        <>
            {/* <Loader loading={loading} /> */}
            <Form
                formData={getFieldsByCategory(formFields, 'funding_details')}
                withValidation={false}
                formValidation={[]}
                extraInputClass=""
            />
            <FundingDetails projectId={projectId} onModalClose={()=>mapFormFields()}/></>
    );
};

export default ProjectFunding;
