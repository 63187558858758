
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Label from "../../ui/Label";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";

// const SectorWiseProgress = () => {
//   const dispatch = useDispatch();

//   const { BeneficiaryCountByThematicArea, ActivityCountByThematicArea } = useSelector(
//     (state) => state?.records?.current
//   )

//   const [thematicAreaActivity, setThematicAreaActivity] = useState([]);
//   const [thematicAreaActivityLabel, setThematicAreaActivityLabel] = useState([]);
//   const [thematicAreaBeneficiary, setThematicAreaBeneficiary] = useState([]);
//   const [thematicAreaBeneficiaryLabel, setThematicAreaBeneficiaryLabel] = useState([]);

//   const activitiesData = {
//     name: "Activities",
//     data: thematicAreaActivity,
//   };
//   const beneficiariesData = {
//     name: "Beneficiaries",
//     data: thematicAreaBeneficiary,
//   };

//   const [series, setSeries] = useState([activitiesData]);
//   const [activeButton, setActiveButton] = useState("Activities");

//   useEffect(()=>{

//     setTimeout(()=>{
//       getData();
//     },200)

//    },[])

//   useEffect(() => {
//     if (ActivityCountByThematicArea && BeneficiaryCountByThematicArea) {
//       setThematicAreaActivity(ActivityCountByThematicArea.data.items.map(({ totalActivityCount }) => totalActivityCount) || []);
//       setThematicAreaActivityLabel(ActivityCountByThematicArea.data.items.map(({ thematicAreaName }) => thematicAreaName) || []);
//       setThematicAreaBeneficiary(BeneficiaryCountByThematicArea.data.items.map(({ totalBeneficiaryCount }) => totalBeneficiaryCount) || []);
//       setThematicAreaBeneficiaryLabel(BeneficiaryCountByThematicArea.data.items.map(({ thematicAreaName }) => thematicAreaName) || []);
//     }

//   }, [ActivityCountByThematicArea, BeneficiaryCountByThematicArea]);

//   useEffect(()=>{
// setSeries([activitiesData]);
// setActiveButton("Activities");
//   },[thematicAreaActivity])



//   const getData =()=>{
//     dispatch({
//       type: getRecords.type,
//       payload: {
//         url: `/Dashboard/GetBeneficiaryCountByThematicArea`,
//         appendCurrentReducerKey: "BeneficiaryCountByThematicArea",
//       },
//     });
// dispatch({
//       type: getRecords.type,
//       payload: {
//         url: `/Dashboard/GetActivityCountByThematicArea`,
//         appendCurrentReducerKey: "ActivityCountByThematicArea",
//       },
//     });
//   }


//   const maximumValue = Math.max(...thematicAreaActivity, ...thematicAreaBeneficiary);



//   const options = {
//     chart: {
//       type: "bar",
//       height: 350,
//       width: "100%",
//       toolbar: {
//         show: false,
//       },
//       legend: {
//         show: false,
//       },
//     },
//     legend: {
//       show: false,
//     },
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: "30%",
//         borderRadius: 8,
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ["transparent"],
//     },
//     xaxis: {
//       categories: activeButton === "Activities" ? thematicAreaActivityLabel : thematicAreaBeneficiaryLabel,
//     },
//     yaxis: {
//       labels: {
//         formatter: function (val) {
//           return Math.round(val) + "k";
//         },
//       },
//       min: 0,
//       max: maximumValue,
//       tickAmount: 6,
//     },
//     fill: {
//       opacity: 1,
//       colors: ["#0E7BB6", "#41824E"],
//     },
//     grid: {
//       show: false,
//     },
//   };

//   const handleActivitiesClick = () => {
//     setSeries([activitiesData]);
//     setActiveButton("Activities");
//   };

//   const handleBeneficiariesClick = () => {
//     setSeries([beneficiariesData]);
//     setActiveButton("Beneficiaries");
//   };

//   return (
//     <div className="bg-white shadow-sm h-100 fundsDonarDistribution">
//       <div className="d-flex w-100 justify-content-between align-items-center">
//         <Label>Sector Wise Progress</Label>
//         <div className="d-flex">
//           <text className="mx-1 sector-buttons" onClick={handleActivitiesClick}>
//             {activeButton === "Activities" ? (
//               <span className="active">Activities</span>
//             ) : (
//               "Activities"
//             )}
//           </text>
//           <text
//             className="mx-1 sector-buttons"
//             onClick={handleBeneficiariesClick}
//           >
//             {activeButton === "Beneficiaries" ? (
//               <span className="active">Beneficiaries</span>
//             ) : (
//               "Beneficiaries"
//             )}
//           </text>
//         </div>
//       </div>
//       <Chart options={options} series={series} type="bar" height={300} />
//     </div>
//   );
// };

// export default SectorWiseProgress;



const SectorWiseProgress = () => {
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width: 425px)");

  const { BeneficiaryCountByThematicArea, ActivityCountByThematicArea } = useSelector(
    (state) => state?.records?.current
  )

  const [thematicAreaActivity, setThematicAreaActivity] = useState([]);
  const [thematicAreaActivityLabel, setThematicAreaActivityLabel] = useState([]);
  const [thematicAreaBeneficiary, setThematicAreaBeneficiary] = useState([]);
  const [thematicAreaBeneficiaryLabel, setThematicAreaBeneficiaryLabel] = useState([]);

  const activitiesData = {
    name: "Activities",
    data: thematicAreaActivity,
  };
  const beneficiariesData = {
    name: "Beneficiaries",
    data: thematicAreaBeneficiary,
  };

  const [series, setSeries] = useState([activitiesData]);
  const [activeButton, setActiveButton] = useState("Activities");

  useEffect(() => {

    setTimeout(() => {
      getData();
    }, 200)

  }, [])

  useEffect(() => {
    if (ActivityCountByThematicArea && BeneficiaryCountByThematicArea) {
      setThematicAreaActivity(ActivityCountByThematicArea?.data?.items.map(({ totalActivityCount }) => totalActivityCount) || []);
      setThematicAreaActivityLabel(ActivityCountByThematicArea?.data?.items.map(({ thematicAreaName }) => thematicAreaName) || []);
      setThematicAreaBeneficiary(BeneficiaryCountByThematicArea?.data?.items.map(({ totalBeneficiaryCount }) => totalBeneficiaryCount) || []);
      setThematicAreaBeneficiaryLabel(BeneficiaryCountByThematicArea?.data?.items.map(({ thematicAreaName }) => thematicAreaName) || []);
    }

  }, [ActivityCountByThematicArea, BeneficiaryCountByThematicArea]);

  useEffect(() => {
    setSeries([activitiesData]);
    setActiveButton("Activities");
  }, [thematicAreaActivity])



  const getData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByThematicArea`,
        appendCurrentReducerKey: "BeneficiaryCountByThematicArea",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetActivityCountByThematicArea`,
        appendCurrentReducerKey: "ActivityCountByThematicArea",
      },
    });
  }


  const maximumValue = Math.max(...thematicAreaActivity, ...thematicAreaBeneficiary);



  const options = {
    chart: {
      type: "donut",
      height: 450,
      width: "100%",
      toolbar: {
        show: true,
      },
      legend: {
        show: true,
      },
    },


    dataLabels: {
      enabled: false,
    },
  };

  const handleActivitiesClick = () => {
    setSeries([activitiesData]);
    setActiveButton("Activities");
  };

  const handleBeneficiariesClick = () => {
    setSeries([beneficiariesData]);
    setActiveButton("Beneficiaries");
  };

  return (
    <div className="bg-white shadow-sm h-100 fundsDonarDistribution">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <Label>Sector Wise Progress</Label>
        <div className="d-flex h-100">
          <text className="mx-1 sector-buttons" onClick={handleActivitiesClick}>
            {activeButton === "Activities" ? (
              <span className="active">Activities</span>
            ) : (
              "Activities"
            )}
          </text>
          <text
            className="mx-1 sector-buttons"
            onClick={handleBeneficiariesClick}
          >
            {activeButton === "Beneficiaries" ? (
              <span className="active">Beneficiaries</span>
            ) : (
              "Beneficiaries"
            )}
          </text>
        </div>
      </div>
      {/* TODO add correct dynamic data */}
      <div className="d-flex justify-content-center ">
          <SimplePieChart
        type="donut"
        // className="overlapping-chart"
        width={isMobileView ? 160 : 300}
        height={isMobileView ? 240 : 300}
        series={activeButton === "Activities" ? thematicAreaActivity : thematicAreaBeneficiary}
        labels={activeButton === "Activities" ? thematicAreaActivityLabel : thematicAreaBeneficiaryLabel}
        position={"bottom"}
        colors={["#554B86", "#0E7BB6"]}
        legendOptions={{
          show: true,
          // formatter: function (seriesName, opts) {
          //   return (
          //     seriesName +
          //     " <br/><strong>" +
          //     opts.w.globals.series[opts.seriesIndex] +
          //     "</strong>"
          //   );
          // },
          // useHTML: true,
        }}
      />
    </div>
      {/* <Chart options={options} series={series} type="donut" height={300} /> */}
      {/* <SimplePieChart
        type="donut"
        width={isMobileView ? 140 : 240}
        height={isMobileView ? 240 : 260}
        series={activeButton === "Activities" ? thematicAreaActivity : thematicAreaBeneficiary}
        labels={activeButton === "Activities" ? thematicAreaActivityLabel : thematicAreaBeneficiaryLabel}
        position={"bottom"}
        colors={["#FF7750", "#0E7BB6"]}
      /> */}
    </div>
  );
};

export default SectorWiseProgress;
