import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import ActivityColumns from "../../assets/columns/ActivityColumns";
import { activityFields } from "../../assets/fields/activityFields";
import Loader from "../loader/loader";
import "./Activities.scss";
import ActivityModal from "./ActivityModal";
import SubActivityModal from "./SubActivityModal";
import { createRecord, formatRecords, putRecord } from "../../redux/reducers";
import DeleteRow from "../../pages/DeleteRow";
import ToolTip from "../tooltip/Tooltip";

const Activities = ({ projectId }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [activityModalIsOpen, setActivityModalIsOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const { created, loading, data } = useSelector((state) => state.records);
  const { items = [] } = data;

  useEffect(() => {
    getSubsctivitiesByProjectId();
  }, [projectId, created]);

  useEffect(() => {
    if (created) closeSubActivityModal();
  }, [created]);

  function openSubActivityModal(row = null) {
    setIsOpen(true);
    if (row) {
      setCurrentRow(row);
      dispatch({ type: formatRecords.type, payload: { ...row } });
    }
  }

  function closeSubActivityModal(row = null) {
    setIsOpen(false);
    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
  }

  function toggleActivityModal() {
    setActivityModalIsOpen(!activityModalIsOpen);
  }

  function getSubsctivitiesByProjectId() {
    dispatch({
      type: "records/getRecords",
      payload: {
        url: `/SubActivity/GetSubActivitiesByProject?ProjectId=${projectId}`,
      },
    });
  }

  let columns = [
    ...ActivityColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Icon.PencilSquare
            className="action-icons"
            onClick={() => openSubActivityModal(row)}
          />
          <DeleteRow row={row} url="/SubActivity" />
        </>
      ),
    },
  ];

  return (
    <div className="table-wrapper smart-indicator mt-4">
      <div className="d-sm-flex">
        <h5 className="font-weight-bold">Sub-Activities</h5>
        <button
          className="btn btn-primary btn-sm ml-auto mb-2"
          onClick={() => openSubActivityModal()}
        >
          <ToolTip label="Add">
            <Icon.Plus />
          </ToolTip>
          Add Sub Activity Name
        </button>
      </div>
      <Loader loading={loading} />
      <DataTable
        columns={columns}
        data={items}
        customStyles={customStyles}
        pagination
        dense
      />
      <SubActivityModal
        modalIsOpen={modalIsOpen}
        toggleSubActivityModal={() => closeSubActivityModal()}
        projectId={projectId}
        isUpdate={currentRow?.id ? true : false}
      />
      {/* <ActivityModal
        modalIsOpen={activityModalIsOpen}
        activityFields={activityFields}
        toggleActivityModal={() => toggleActivityModal()}
      /> */}
    </div>
  );
};

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#EFF5FD",
      paddingBottom: 10,
      paddingTop: 10,
    },
  },
  cells: {
    style: {},
  },
};



export default Activities;
