const ComplaintResponseColumns = [
  {
    name: "Complainant Level Of Satisfaction",
    selector: (row) => row?.complainantLevelOfSatisfaction,
  },
  {
    name: "Mode Of Responding",
    selector: (row) => row?.modeOfResponding,
  },
  {
    name: "Comments",
    selector: (row) => row?.comments,
  },
  {
    name: "Appeal Response",
    selector: (row) => row?.appeal,
  },
];

export default ComplaintResponseColumns;
