import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearForm, getRecords } from "../../redux/reducers";
import Form from "../form/Form";

const FilterAccordian = ({
  formFields = [],
  toggleFilter = false,
  url = "",
}) => {
  const dispatch = useDispatch();

  const handleClear = () => {
    dispatch({ type: clearForm.type });
  };
  const handleSearch = (params = null) => {
    let newParams = {};

    if (params)
      Object.entries(params).forEach(([key, item]) => {
        newParams = {
          ...newParams,
          [key]:
            typeof item?.value == "object" ? item?.value.value : item?.value,
        };
      });

    dispatch({
      type: getRecords.type,
      payload: { params: newParams, url },
    });
  };
  return (
    <div
      className={`d-${toggleFilter ? "block" : "none"} my-2 border rounded p-3`}
    >
      <h6 className="py-2 mb-0 mt-1 border-bottom">Filter By:</h6>
      <Form
        formData={formFields}
        onSubmit={(params) => handleSearch(params)}
        // withValidation={true}
        // formValidation={validations}
        extraInputClass=""
      />
      <div className="ml-auto mt-2 text-right position-relative">
        <button
          onClick={() => handleClear()}
          type="button"
          className="btn btn-danger "
          style={{ position: "absolute", right: "90px", bottom: "8px" }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default FilterAccordian;
