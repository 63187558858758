import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../redux";
import { FaArrowCircleLeft } from "react-icons/fa";

const LightBoxGallery = () => {
  const { state } = useLocation();
  const { imageList =[], index=0 } = state;
  const navigate = useNavigate();
  console.log("🚀 ~ LightBoxGallery ~ imageList:", imageList)

  const updatedImagesUrl = imageList?.filter(({ url }) => url)
    .map(({ url,caption }) => ({
      original: `${baseUrl}${url}`,
      thumbnail: `${baseUrl}${url}`,
      description:caption || ""
     
    }));


  return (
    <>
      <button onClick={()=>{navigate(-1)}} className=" bg-transparent text-secondary border-0 " style={{display:"flex",justifyContent:"center", alignItems:"center", gap:"10px"}}>  <span><FaArrowCircleLeft style={{fontSize:"20px"}}/></span> <span style={{textDecoration:"underline",marginTop:"4px"}}>Go Back</span></button>
       <ImageGallery items={updatedImagesUrl} startIndex={index}  />
    </>
  );
};

export default LightBoxGallery;
