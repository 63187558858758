import React, { useEffect } from "react";
import SimplePieChart from "../../chart/SimplePieChart";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Label from "../../ui/Label";
import { getPieFormatedDataByArray } from "../../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";

const NatureOfComplaint = () => {
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width: 768px)");

  const { natureOfComplaint } = useSelector((state) => state.records?.current);
  const pieChartFormatData = getPieFormatedDataByArray(natureOfComplaint?.data?.items);

  // const typeOfComplaint = {
  //   values: [0, 9, 0, 91, 0,],
  //   labels: ["Beneficiary", "non-Beneficiary", "SIF Staff", "Partner Staff", "Other"],
  // };


  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetNatureOfComplaintStats`,
        appendCurrentReducerKey: "natureOfComplaint",
      },
    });
  }, []);


  // const natureOfComplaint = {
  //   values: [10, 30, 15],
  //   labels: ["FeedBacks", "Serious Complaints", "Regular Complaints"],
  // };
  return (
    <div className="bg-white shadow-sm recieve-container">
      <Label>Nature of Complaint</Label>
      <div className="d-flex justify-content-center">
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 260}
          height={isMobileView ? 240 : 260}
          series={pieChartFormatData?.values ?? "Loading..."}
          labels={pieChartFormatData?.labels ?? "Loading..."}
          position={"bottom"}
          colors={[
            "#01CC9B",
            "#1DDBA9",
            "#0075B6"
          ]}
        />
      </div>
    </div>
  );
};

export default NatureOfComplaint;
