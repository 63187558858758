import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import Form from "../../components/form/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import { getFieldsByCategory } from "../../utils/util";

const AddUpdateMonitoringVisitReport = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();
  const formFields = useSelector((state) => state.fields.formFields);
  const { loading, created, data } = useSelector((state) => state.records);
  const formValues = useSelector((state) => state.form.values);
  const { userDetail } = useSelector((state) => state.setting.user);
  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    dispatch({
      type: "fields/fetchLayout",
      payload: "/json/monitoring-visit-fields",
    });
  }, []);

  useEffect(() => {
    if (created) {
      navigate("/monitoring-visit-report");
    }
  }, [created]);

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/MonitoringVisitReport/${id}` },
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);
  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  const addRecord = () => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...formValues,
        reportingPerson: userDetail,
        url: "/MonitoringVisitReport",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  };

  function updateRecord() {
    dispatch({
      type: putRecord.type,
      payload: {
        ...formValues,
        url: "/MonitoringVisitReport",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  }

  return (
    <div className="mb-4">
      <Loader loading={loading} />
      <FormStepper
        initialStep={0}
        onSubmit={() => (canUpdate ? updateRecord() : addRecord())}
      >
        <Step key="projectDetail" title="Monitoring Visit Details">
          <Form
            formData={getFieldsByCategory(formFields, "projectDetail")}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Step>
        <Step key="reportDetail" title="De-Brief Details">
          <div className="col-12">
            <Form
              formData={getFieldsByCategory(formFields, "reportDetail")}
              withValidation={false}
              formValidation={[]}
              extraInputClass=""
            />
          </div>
        </Step>
      </FormStepper>
    </div>
  );
};

export default AddUpdateMonitoringVisitReport;
