import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { fundingLineFields } from "../../assets/fields/fundingLineFields";
import Form from "../form/Form";
import "./FundingDetails.scss";
import FundingOrganizationColumns from "../../assets/columns/FundingOrganizationColumns";
import { createRecord, formatRecords } from "../../redux/reducers";
import DeleteRow from "../../pages/DeleteRow";

const FundingDetails = ({ projectId, onModalClose }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [currentRow, setCurrentRow] = useState({});
  let canCreateFunding = false;

  const { loading, data } = useSelector((state) => state.records);
  const created = useSelector((state) => state.records.created);
  const { fundingDetails = [] } = data;

  const totalFundingAmountByOrganization =
    fundingDetails &&
    fundingDetails.reduce((value, { amount = 0 }) => {
      return value + amount;
    }, 0);
  canCreateFunding =
    data?.totalCostInLocalCurrency > totalFundingAmountByOrganization;

  useEffect(() => {
    if (created) {
      closeModal();
      dispatch({
        type: "records/getRecords",
        payload: { url: `/project/${projectId}` },
      });
    }
  }, [created]);

  function openModal(row = null) {
    dispatch({ type: "form/clearForm" });
    if (row) {
      setCurrentRow(row);
      dispatch({ type: formatRecords.type, payload: { ...row } });
    }
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setCurrentRow({});
    onModalClose();
    // dispatch({ type: 'form/clearForm' })
  }

  function afterOpenModal() {}

  const columns = [
    ...FundingOrganizationColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Icon.PencilSquare
            className="action-icons"
            onClick={() => openModal(row)}
          />{" "}
          <DeleteRow row={row} url="/FundingDetail" />
        </>
      ),
    },
  ];

  function postData(params) {
    const amountExceeded =
      data?.totalCostInLocalCurrency - totalFundingAmountByOrganization <
      (params.amount ? parseInt(params.amount.value) : 0);
    if (amountExceeded) {
      toast.warning("Amount exceeded than the mentioned budget");
    } else
      dispatch({
        type: createRecord.type,
        payload: {
          ...params,
          url: "/FundingDetail",
          projectId: { value: projectId },
        },
      });
  }

  const handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);

  return (
    <div className="table-wrapper smart-indicator mt-4">
      <div className="d-sm-flex">
        <h5 className="font-weight-bold">Funding Details</h5>
        {canCreateFunding && (
          <button
            className="ml-auto btn btn-primary mb-2"
            style={{ cursor: "pointer" }}
            onClick={() => openModal()}
          >
            + Create New Funding Line
          </button>
        )}
      </div>
      <DataTable
        columns={columns}
        data={fundingDetails}
        onSort={handleSort}
        customStyles={customStyles}
        pagination
        dense
      />
      <Modal
        isOpen={modalIsOpen && !created}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Smart Indicator"
      >
        <Icon.XCircle onClick={closeModal} className="action-icons" />
        <h4>Funding Details</h4>
        <Form
          formData={fundingLineFields}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </div>
  );
};

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#EFF5FD",
      paddingBottom: 10,
      paddingTop: 10,
    },
  },
  cells: {
    style: {},
  },
};

export default FundingDetails;
