import RELATION_SHIP from "../enums/RELATIONSHIP";

const relationshipTypes =
    Object.keys(RELATION_SHIP).map((i) => {
        return {
            label: RELATION_SHIP[i],
            value: RELATION_SHIP[i]
        }
    })

export default relationshipTypes;