import React from "react";
import ActivityMap from "./ActivityMap";
import "./ActivityMap.scss";
import Filters from "./Filters";

const ActivityMapTab = () => {
  return (
    <div class="container-fluid p-0 my-4">
      <div class="row px-0 g-2 row-container">
        <div class="col-12 col-map">
          <ActivityMap />
        </div>
        {/* <div class="col-3">
          <Filters />
        </div> */}
      </div>
    </div>
  );
};

export default ActivityMapTab;
