import { COMPLAINT_STATUS_TYPE } from "../../Constants";

const complaintStatusTypes = [
    {
        label: COMPLAINT_STATUS_TYPE.PENDING,
        value: COMPLAINT_STATUS_TYPE.PENDING
    },
    {
        label: COMPLAINT_STATUS_TYPE.CLOSED,
        value: COMPLAINT_STATUS_TYPE.CLOSED
    },
]
export default complaintStatusTypes;