import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
// import { activityFields } from "../../assets/fields/activityFields";
import Form from "../form/Form";
import "./Activities.scss";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, getRecords, putRecord } from "../../redux/reducers";


const ActivityModal = ({
  modalIsOpen,
  toggleActivityModal,
  activityFields,
  projectId="",
  rowId="",
}) => {


  const dispatch = useDispatch();
  
  function postData(params) {
    let formData = { ...params, Id: { value: rowId } };
    dispatch({
      type: putRecord.type,
      payload: { ...formData, url: "/ERActivity" },
    });

    toggleActivityModal()
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Smart Indicator"
      >
        <Icon.XCircle onClick={toggleActivityModal} className="action-icons" />
        <h4>Activity</h4>
        <Form
          formData={activityFields}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </>
  );
};

export default ActivityModal;
