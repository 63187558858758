export const baseUrl = "http://203.124.35.18:8585";

const servers = {
    'localhost': "http://localhost:3000",
    'project-demos.com': "http://project-demos.com/sif",
    'sif.hostober.pk': "http://sif.hostober.pk",
    '203.124.35.18':"http://203.124.35.18:8057"
  }

const getServer = (host) =>{
  const server = servers[host];
  if(server)
  return server;

  return servers['sif.hostober.pk'];
}

export const baseClientUrl = getServer(window.location.hostname);