import "./Column.scss";

const ColumnInnerList = ({ items = [], ...otherProps }) => {
    if (!Array.isArray(items))
        return <></>;
    return (<ul className="list">
        {items && items.map((item, index) => (<li className="list-item" key={index}>{item}</li>))}
    </ul>);
}

export default ColumnInnerList;