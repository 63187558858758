import genderTypes from "./../values/genderTypes";
import ageBrackets from "./../values/ageBrackets";
import relationshipTypes from "../values/relationshipTypes";

const beneficiaryFields = [
  // {
  //   "category": "details",
  //   "label": "Project Title",
  //   "placeholder": "Enter Project Title",
  //   "name": "project",
  //   "inForm": true,
  //   "wrapperClass": "col-md-6"
  // },
  // {
  //   "category": "details",
  //   "label": "Activity Name",
  //   "placeholder": "Enter Activity Name",
  //   "name": "activity",
  //   "inForm": true,
  //   "wrapperClass": "col-md-6"
  // },
  {
    category: "details",
    label: "Beneficiary Name",
    placeholder: "Enter Beneficiary Name",
    name: "name",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Gender",
    placeholder: "Select Gender",
    name: "gender",
    id: "gender",
    inputType: "async-dropdown",
    options: genderTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    wrapperClass: "col-md-6",
    // "value": { label: 'Male', value: { id: 'Male', value: 'Male' } },
    isMulti: false,
    isEnum: true,
  },
  {
    category: "details",
    label: "Age",
    placeholder: "Enter Age",
    name: "age",
    inForm: true,
    type: "number",
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Phone",
    placeholder: "Enter Phone",
    name: "phoneNo",
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 9,
      max: 11,
    },
  },
  {
    category: "details",
    label: "CNIC/ POR No.",
    placeholder: "CNIC/ POR No.",
    type: "number",
    name: "cnic",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 13,
      max: 13,
    },
  },
  // {
  //   "category": "details",
  //   "label": "Project",
  //   "placeholder": "Select Project",
  //   "name": "project",
  //   "inputType": "async-dropdown",
  //   "url": "/project/GetAll?DisablePagination=true",
  //   "searchAttrib": "title",
  //   "id": "project",
  //   "labelAttribute": "title",
  //   "valueAttribute": "id",
  //   "onChangeMethod": "asyncdropdown",
  //   "inForm": true,
  //   "wrapperClass": "col-md-6",
  //   "isMulti": false
  // },
  // {
  //   "category": "details",
  //   "label": "Activity",
  //   "placeholder": "Select Activity",
  //   "name": "activity",
  //   "id": "activity",
  //   "inputType": "async-dropdown",
  //   "url": "/ERActivity/GetActivities",
  //   "searchAttrib": "name",
  //   "labelAttribute": "name",
  //   "valueAttribute": "id",
  //   "onChangeMethod": "asyncdropdown",
  //   "inForm": true,
  //   "wrapperClass": "col-md-6",
  //   "isMulti": false,
  //   required: false,
  // },
  // {
  //   "category": "details",
  //   "label": "Sub Activities",
  //   "placeholder": "Select Sub Activities",
  //   "name": "subActivities",
  //   "id": "subActivities",
  //   "inputType": "async-dropdown",
  //   "url": "/SubActivity/GetActivitiesByProject",
  //   "dependency": "project",
  //   "searchAttrib": "name",
  //   "labelAttribute": "name",
  //   "valueAttribute": "id",
  //   "onChangeMethod": "asyncdropdown",
  //   "inForm": true,
  //   "wrapperClass": "col-md-6",
  //   "isMulti": false,
  //   required: false,
  // },
  {
    category: "details",
    category: "project-detail",
    label: "Thematic Area",
    placeholder: "Add Thematic Area",
    name: "thematicAreas",
    inputType: "async-dropdown",
    url: "/ThematicArea/GetAll",
    searchAttrib: "name",
    id: "thematicAreas",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  // {
  //   category: "details",
  //   label: "Sustainable Development Goals",
  //   placeholder: "Add Sustainable Development Goals",
  //   name: "sdGs",
  //   inputType: "async-dropdown",
  //   url: "/SDG/GetAll",
  //   searchAttrib: "name",
  //   id: "sdGs",
  //   labelAttribute: "name",
  //   valueAttribute: "id",
  //   onChangeMethod: "asyncdropdown",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  //   isMulti: true
  // },
  {
    category: "details",
    label: "Vulnerability",
    placeholder: "Select Vulnerability",
    name: "vulnerabilities",
    id: "vulnerabilities",
    inputType: "async-dropdown",
    url: "/Vulnerability/GetAll",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  {
    category: "address",
    name: "address_label",
    inputType: "h4",
    children: "Residential Address",
    inForm: true,
    wrapperClass: "col-12 py-3",
  },
  {
    category: "address",
    label: "Addresses",
    inputType: "tableInput",
    inForm: true,
    name: "addresses",
    header: [
      {
        label: "Province",
        placeholder: "Enter Province",
        name: "province",
        id: "province",
        inputType: "async-dropdown",
        url: "/Province/GetAll",
        searchAttrib: "name",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "District",
        placeholder: "Enter District",
        name: "district",
        id: "district",
        inputType: "async-dropdown",
        url: "/District/GetAll",
        searchAttrib: "name",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "Tehsil",
        placeholder: "Enter Tehsil",
        name: "tehsil",
        id: "tehsil",
        inputType: "async-dropdown",
        url: "/Tehsil/GetAll",
        searchAttrib: "name",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "UC",
        placeholder: "Enter UC",
        name: "unionCouncil",
        id: "unionCouncil",
        inputType: "async-dropdown",
        url: "/UnionCouncil/GetAll",
        searchAttrib: "name",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "Village",
        placeholder: "Enter Village",
        name: "village",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
        onChangeMethod: "text-in-table",
      },
      {
        label: "Residential Address",
        placeholder: "Enter Address",
        name: "resAddress",
        inForm: true,
        onChangeMethod: "text-in-table",
      },
    ],
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
  },
  {
    category: "address",
    name: "household_label",
    inputType: "h4",
    children: "Household Details",
    inForm: true,
    wrapperClass: "col-12 pt-3",
  },
  {
    category: "address",
    label: "Head of Household Name",
    placeholder: "Enter Name",
    name: "headOfHouseHoldName",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "address",
    label: "Relation of Head of Household with Beneficiary (Leave Blank If Self)",
    placeholder: "Enter Realtion",
    name: "relationshipWithBeneficiary",
    id: "relationshipWithBeneficiary",
    inputType: "async-dropdown",
    options: relationshipTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "name",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "address",
    label: "People in Household",
    inputType: "tableInput",
    inForm: true,
    name: "peopleInHousehold",
    header: [
      {
        label: "Age Bracket",
        placeholder: "Select Age Bracket",
        name: "ageRange",
        id: "ageRange",
        inputType: "async-dropdown",
        // options: ageBrackets,
        url: "/AgeRange/GetAll",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-md-6",
        searchAttrib: "name",
        labelAttribute: "name",
        valueAttribute: "id",
        inForm: true,
        wrapperClass: "col-md-6",
        isMulti: false,
      },
      {
        label: "Number of Female",
        placeholder: "Enter Number",
        name: "noOfFemales",
        type: "number",
        onChangeMethod: "text-in-table",
        inForm: true,
        wrapperClass: "col-md-6",
      },
      {
        label: "Number of Male",
        placeholder: "Enter Number",
        name: "noOfMales",
        type: "number",
        onChangeMethod: "text-in-table",
        inForm: true,
        wrapperClass: "col-md-6",
      },
    ],
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
  },
];
export default beneficiaryFields;
