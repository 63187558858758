import React, { useEffect, useState } from "react";
import DonutChart from "../../components/chart/DonutChart";
import { getRecords } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import SimpleCard from "../../components/card/SimpleCard";
import { getPieFormatedDataByArray } from "../../utils/util";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import useMediaQuery from "../../hooks/useMediaQuery";

const ComplaintDashboard = () => {
  const [projectId, setProjectId] = useState(null);

  const { current } = useSelector((state) => state.records);
  const {
    receivingModeStats,
    typeOfComplaintStats,
    natureOfComplaintStats,
    statusOfTreatmentStats,
    activitiesStats,
    ageStats,
    projectStats,
    genderStats,
    projectList,
  } = current;

  const isMobileView = useMediaQuery("(max-width: 425px)");
  const dispatch = useDispatch();

  const receivingModeStatsData = getPieFormatedDataByArray(
    receivingModeStats?.data?.items
  );
  const typeOfComplaintStatsData = getPieFormatedDataByArray(
    typeOfComplaintStats?.data?.items
  );
  const natureOfComplaintStatsData = getPieFormatedDataByArray(
    natureOfComplaintStats?.data?.items
  );
  const statusOfTreatmentStatsData = getPieFormatedDataByArray(
    statusOfTreatmentStats?.data?.items
  );
  const activitiesStatsData = getPieFormatedDataByArray(
    activitiesStats?.data?.items
  );
  const ageStatsData = getPieFormatedDataByArray(ageStats?.data?.items);
  const projectStatsData = getPieFormatedDataByArray(projectStats?.data?.items);
  const genderStatsData = getPieFormatedDataByArray(genderStats?.data?.items);

  const getReceivingModeStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetReceivingModeStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetReceivingModeStats?DisablePagination=true`,
        appendCurrentReducerKey: "receivingModeStats",
      },
    });
  };

  const getTypeOfComplaintStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetTypeOfComplainantStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetTypeOfComplainantStats?DisablePagination=true`,
        appendCurrentReducerKey: "typeOfComplaintStats",
      },
    });
  };
  const getNatureOfComplaintStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetNatureOfComplaintStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetNatureOfComplaintStats?DisablePagination=true`,
        appendCurrentReducerKey: "natureOfComplaintStats",
      },
    });
  };
  const getStatusOfTreatmentStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetStatusOfTreatmentStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetStatusOfTreatmentStats?DisablePagination=true`,
        appendCurrentReducerKey: "statusOfTreatmentStats",
      },
    });
  };
  const getActivitiesStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetActivitiesStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetActivitiesStats?DisablePagination=true`,
        appendCurrentReducerKey: "activitiesStats",
      },
    });
  };
  const getAgeStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetAgeStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetAgeStats?DisablePagination=true`,
        appendCurrentReducerKey: "ageStats",
      },
    });
  };
  const getProjectStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetProjectStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetProjectStats?DisablePagination=true`,
        appendCurrentReducerKey: "projectStats",
      },
    });
  };
  const getGenderStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: projectId
          ? `/ComplaintDashboard/GetGenderStats?Project.Id=${projectId}`
          : `/ComplaintDashboard/GetGenderStats?DisablePagination=true`,
        appendCurrentReducerKey: "genderStats",
      },
    });
  };

  const getProjectList = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/project/GetAll",
        appendCurrentReducerKey: "projectList",
      },
    });
  };

  useEffect(() => {
    getProjectStats();

    getActivitiesStats();
    getStatusOfTreatmentStats();
    getNatureOfComplaintStats();
    getTypeOfComplaintStats();
    getReceivingModeStats();
    getAgeStats();
    getGenderStats();
    setTimeout(() => {
      getProjectList();
    }, 200);
  }, [projectId]);
  return (
    <div className="main">
      <div className="my-3 row justify-content-end">
        <div className="col-12 d-md-flex align-items-center w-md-50">
          <label htmlFor="exampleFormControlSelect2" style={{ width: "75px" }}>
            Filter by:
          </label>
          <select
            className="form-control"
            id="exampleFormControlSelect2"
            onChange={(e) => setProjectId(e.target.value)}
          >
            <option value="" selected>
              All Project
            </option>
            {projectList &&
              projectList.data.items.map(({ title, id }) => (
                <option key={id} value={id} style={{ color: "black" }}>
                  {title}
                </option>
              ))}
          </select>
        </div>
      </div>
      {/* first row */}
      <div className="row">
        <div className="col-md-6 col-lg-4">
          <SimpleCard title={"Recieving Mode"}>
            <DonutChart
              type="donut"
              width={isMobileView ? 150 : 200}
              height={300}
              series={receivingModeStatsData.values}
              labels={receivingModeStatsData.labels}
            />
          </SimpleCard>
        </div>
        <div className="col-md-6 col-lg-4">
          <SimpleCard title={"Type of Complaints"}>
            <DonutChart
              type="donut"
              width={isMobileView ? 150 : 200}
              height={300}
              series={typeOfComplaintStatsData.values}
              labels={typeOfComplaintStatsData.labels}
            />
          </SimpleCard>
        </div>
        <div className="col-md-6 col-lg-4">
          <SimpleCard title={"Nature of Complaints"}>
            <DonutChart
              type="donut"
              width={isMobileView ? 150 : 200}
              height={300}
              series={natureOfComplaintStatsData.values}
              labels={natureOfComplaintStatsData.labels}
            />
          </SimpleCard>
        </div>
      </div>

      {/* second row */}
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col">
              <SimpleCard title={"Status of Treatment"}>
                <DonutChart
                  width={isMobileView ? 150 : 200}
                  height={300}
                  series={statusOfTreatmentStatsData.values}
                  labels={statusOfTreatmentStatsData.labels}
                />
              </SimpleCard>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <SimpleCard title={"Activities"}>
                <DonutChart
                  width={isMobileView ? 150 : 200}
                  height={300}
                  series={activitiesStatsData.values}
                  labels={activitiesStatsData.labels}
                />
              </SimpleCard>
            </div>
          </div>
        </div>
        <div className="col-md-6 h-100">
          <SimpleCard title={"Projects"}>
            {projectStatsData.labels.map((lbl, ind) => (
              <ProgressBar
                label={lbl}
                value={projectStatsData.values[ind]}
                key={ind}
              />
            ))}
          </SimpleCard>
        </div>
      </div>

      {/* 3rd Row */}
      <div className="row">
        <div className="col-md-6">
          <SimpleCard title={"Age Stats"}>
            <DonutChart
              width={isMobileView ? 150 : 200}
              height={300}
              series={ageStatsData.values}
              labels={ageStatsData.labels}
            />
          </SimpleCard>
        </div>
        <div className="col-md-6">
          <SimpleCard title={"Gender Stats"}>
            <DonutChart
              width={isMobileView ? 150 : 200}
              height={300}
              series={genderStatsData.values}
              labels={genderStatsData.labels}
            />
          </SimpleCard>
        </div>
      </div>
    </div>
  );
};

export default ComplaintDashboard;
