import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { INTERVENTION_LOGIC_TYPE } from '../../Constants';
import { clearForm, createRecord } from '../../redux/reducers';

import Form from '../form/Form';
import Loader from '../loader/loader';
import { useParams } from 'react-router-dom';

const AddSpecificObjective = () => {

    let { id } = useParams();
    const projectId = id;
    const dispatch = useDispatch();
    const { formFields } = useSelector((state) => state.fields);
    const formValues = useSelector((state) => state.form.values);
    const { created, loading, response = {}, data = {} } = useSelector((state) => state.records);
    const { specificObjectives = {} } = useSelector((state) => state.records?.current);

    //by default clear previous step form data
    useEffect(() => {
        dispatch({ type: clearForm.type })
    }, [])

    useEffect(() => {
        getSpecificObjectives();
    }, [created])


   


    const onAdd = (params) => {
        let formData = { ...params, interventionLogicType: { value: INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE }, projectId: { value: projectId } }
        dispatch({ type: createRecord.type, payload: { ...formData, url: '/InterventionLogic' } })
    };

    function getSpecificObjectives() {
        if(projectId){
            dispatch({
                type: "records/getRecords",
                payload: {
                    url: `/InterventionLogic/GetSpecificObjectives?ProjectId=${projectId}`,
                    appendCurrentReducerKey: "specificObjectives",
                },
            });
        }
    }

    function getFieldsByCategory(fields = [], key) {
        return fields.filter(({ category = '' }) => category == key)
    }

    return (
        <div className="position-relative">
            <Loader loading={loading} theme="light" />
            <Form
                formData={getFieldsByCategory(formFields, 'intervention')}
                onSubmit={(params) => onAdd(params)}
                withValidation={false}
                formValidation={[]}
                extraInputClass=""
            />
            <hr />
            <div>
                <div className='font-weight-bold'>Specific Objectives List</div>
                <div className="">
                    <div className="row">
                        {
                       specificObjectives?.data?.items.length > 0 ?     (specificObjectives?.data?.items ?? []).map((item, index) => (
                                <div className="col-md-4" key={index}>
                                    <div className="border p-2 mb-2  text-center">
                                        {item?.interventionLogicName}
                                    </div>
                                </div>
                            ))

                            : <div className='p-3'>No Specific Objectives Found</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddSpecificObjective;