import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { userManagementFields } from "../../assets/fields/userManagementFields";
import Form from "../../components/form/Form";
import Loader from "../../components/loader/loader";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";

const AddUpdateUserManagement = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, created, data } = useSelector((state) => state.records);
  const { values, validations } = useSelector((state) => state.form);
  const formValues = values;

  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    if (created && !canUpdate) {
      navigate("/UserManagement");
    }
  }, [created]);

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/UserManagement/${id}` },
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  function addRecord(params) {
    const { addresses, ...otherParams } = params;
    const insertProperty = addresses.value.map((obj) => ({
      ...obj,
      parentType: "User",
    }));
    const newAddresses = { ...addresses, value: insertProperty };
    const newParams = { ...otherParams, addresses: newAddresses };
    dispatch({
      type: createRecord.type,
      payload: {
        ...newParams,
        url: "/UserManagement",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  }

  function updateRecord(params) {
    const { addresses, ...otherParams } = params;
    const insertProperty = addresses.value.map((obj) => ({
      ...obj,
      parentType: "User",
    }));
    const newAddresses = { ...addresses, value: insertProperty };
    const newParams = { ...otherParams, addresses: newAddresses };

    dispatch({
      type: putRecord.type,
      payload: {
        ...newParams,
        url: "/UserManagement",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  }

  const formFields = canUpdate
    ? userManagementFields.filter(
        ({ name }) => name != "password" && name != "confirmPassword"
      )
    : userManagementFields;

  return (
    <div className="col-12">
      <Loader loading={loading} />
      <Form
        formData={formFields ? formFields : []}
        onSubmit={(params) =>
          canUpdate ? updateRecord(params) : addRecord(params)
        }
        withValidation={true}
        formValidation={validations}
        extraInputClass=""
      />
    </div>
  );
};

export default AddUpdateUserManagement;
