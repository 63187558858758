import React, { useEffect, useState } from "react";
import "./TopInfoView.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";
const TopInfoView = () => {
  const dispatch = useDispatch();
  const { topInfo, totalBeneficiaries, activitiesCountData, fundingProjectCountData } = useSelector((state) => state.records?.current);
  const getData = () => {

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetProjectStatus`,
        appendCurrentReducerKey: "topInfo",
      },
    });

    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Beneficiary/GetAll`,
        appendCurrentReducerKey: "totalBeneficiaries",
      },
    });


    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetActivitiesCount`,
        appendCurrentReducerKey: "activitiesCountData",
      },
    });


    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetFundingOrganizationCount`,
        appendCurrentReducerKey: "fundingProjectCountData",
      },
    });

  }

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 200)
  }, [])

  return (
    <>
      <div className="top-info-view w-100 h-100 bg-white shadow-sm py-3 px-4 mt-4">
        <div className="text-container">
          <text className="title pb-2">Completed Projects</text>
          {/* <h3 className="value">{data?.items}</h3> */}
          <h3 className={`value`}>{topInfo?.data?.items[0]?.completedProjects ?? 0}</h3>

        </div>
        <div className="separate-bar"></div>
        <div className="text-container">
          <text className="title  pb-2">Ongoing Projects</text>
          <h3 className={`value`}>{topInfo?.data?.items[0]?.ongoingProjects ?? 0}</h3>
        </div>
        <div className="separate-bar"></div>
        <div className="text-container">
          <text className="title pb-2">Total Beneficiaries</text>
          <h3 className={`value`}>{totalBeneficiaries?.data._meta.totalCount ?? 0}</h3>
        </div>
        <div className="separate-bar"></div>
        <div className="text-container">
          <text className="title pb-2">Ongoing Activities</text>
          <h3 className={`value`}>{activitiesCountData?.data?.items[1]?.activityCount ?? 0}</h3>
        </div>
        <div className="separate-bar"></div>
        {/*TODO Make below 3 cards dynamic */}
        <div className="text-container">
          <text className="title pb-2">Core Funded Projects</text>
          {/* <h3 className="value">{data?.items}</h3> */}
          <h3 className={`value`}>{fundingProjectCountData?.data?.items[0]?.coreProjectsCount ?? 0}</h3>

        </div>
        <div className="separate-bar"></div>
        <div className="text-container">
          <text className="title  pb-2">Donor Funded Projects</text>
          <h3 className={`value`}>{fundingProjectCountData?.data?.items[0]?.donorProjectsCount ?? 0}</h3>
        </div>
       
      </div>
      {/* 2nd row of cards here */}
      {/* <div className="top-info-view w-100 h-100 bg-white shadow-sm py-3 px-4 mt-4">
      <div className="text-container">
        <text className="title pb-2">Core Funded Projects</text>
        <h3 className={`${topInfo?.data?.items[0]?.ongoingProjects>=0 ? "value" : "value-loading"}`}>{topInfo?.data?.items[0]?.completedProjects ?? "Loading..."}</h3>

      </div>
      <div className="separate-bar"></div>
      <div className="text-container">
        <text className="title  pb-2">Donor Funded Projects</text>
        <h3 className={`${topInfo?.data?.items[0]?.ongoingProjects>=0 ? "value" : "value-loading"}`}>{topInfo?.data?.items[0]?.ongoingProjects ?? "Loading..."}</h3>
      </div>
      <div className="separate-bar"></div>
      <div className="text-container">
        <text className="title pb-2">Sector Wise Projects</text>
        <h3 className={`${topInfo?.data?.items[0]?.ongoingProjects>=0 ? "value" : "value-loading"}`}>{totalBeneficiaries?.data._meta.totalCount ?? "Loading..."}</h3>
      </div>
      <div className="separate-bar"></div>
      <div className="text-container">
        <text className="title pb-2">Ongoing Activities</text>
        <h3 className={`${topInfo?.data?.items[0]?.ongoingProjects>=0 ? "value" : "value-loading"}`}>59</h3>
      </div>
    </div> */}
    </>
  );
};

export default TopInfoView;
