import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import ProgressBar from "../../components/progress-bar/ProgressBar";
import Loader from "../../components/loader/loader";
import Table from "../../components/table/Table";
import "./SpecificObjective.scss";
import Form from "../../components/form/Form";
import { achievementFields } from "../../assets/fields/achievementFields";
import { createRecord } from "../../redux/reducers";
import ToolTip from "../../components/tooltip/Tooltip";
import { FaArrowCircleLeft } from "react-icons/fa";

const SpecificObjective = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [state, setState] = useState({
    modalIsOpen: false,
    achieveModalIsOpen: false,
    selectedIndicator: null,
  });
  const { data, loading, current, created } = useSelector(
    (state) => state.records
  );
  const { ilDetails = {} } = current;
  const ilDetailsData = ilDetails?.data;
  const {
    items = [],
    interventionLogicName = "",
    interventionLogicType = "",
  } = data;

  function toggleFundingDetailModal(indicator = null) {
    setState({
      ...state,
      modalIsOpen: !state.modalIsOpen,
      selectedIndicator: indicator,
    });
  }

  function toggleAchievmentModal(indicator = null) {
    setState({
      ...state,
      achieveModalIsOpen: !state.achieveModalIsOpen,
      selectedIndicator: indicator ? indicator : state.selectedIndicator,
    });
  }

  useEffect(() => {
    // dispatch({ type: "records/getRecords", payload: { url: `/LogFrame/GetSmartIndicatorsforIL?InterventionLogicId=${id}` } })
    dispatch({
      type: "records/getRecords",
      payload: {
        url: `/LogFrame/GetSmartIndicatorsforIL?InterventionLogicId=${id}`,
      },
    });
  }, []);

  useEffect(() => {
    if (state.selectedIndicator)
      dispatch({
        type: "records/getRecords",
        payload: {
          url: `/LogFrame/GetILSmartIndicatorDetail?id=${state.selectedIndicator?.indicatorId}`,
          appendCurrentReducerKey: "ilDetails",
        },
      });
  }, [state.selectedIndicator]);

  useEffect(() => {
    if (created) toggleAchievmentModal();
  }, [created]);

  const columns = [
    {
      name: "Indicator",
      selector: (row) => (
        <>
          <label
            className="text-primary cursor-pointer text-capitalize"
            onClick={() => toggleFundingDetailModal(row)}
          >
            {row?.indicatorName}
          </label>
        </>
      ),
    },
    {
      name: "Indicator Achievement",
      selector: (row) => row?.indicatorAchievement,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Indicator Details">
            <Icon.Folder2Open
              onClick={() => toggleFundingDetailModal(row)}
              className="action-icons"
            />
          </ToolTip>
          <ToolTip label="Add Achievement">
            <Icon.PlusCircle
              className="action-icons"
              onClick={() => toggleAchievmentModal(row)}
            />
          </ToolTip>
          {/* <Icon.PencilSquare className="action-icons" />
          <Icon.Trash className="action-icons" /> */}
        </>
      ),
    },
  ];

  function postAchievmentData(params) {
    const paramData = {
      ...params,
      SmartIndicator: {
        name: "SmartIndicator",
        value: {
          value: state.selectedIndicator.indicatorId,
          label: state.selectedIndicator.indicatorName,
        },
      },
    };
    dispatch({
      type: createRecord.type,
      payload: {
        ...paramData,
        url: "/SIAchievement",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  }

  function getAchievementRate(value) {
    return value ? parseInt(value.replace(/[`%\{\}\[\]\\\/]/gi, "")) : 0;
  }

  return (

   <>

<hr />
      <button onClick={() => { navigate(-1) }} className=" bg-transparent text-secondary border-0 " style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>  <span><FaArrowCircleLeft style={{ fontSize: "20px" }} /></span> <span style={{ textDecoration: "underline", marginTop: "4px" }}>Go Back</span></button>
   
     <div className="specific-objectives">
      <Loader loading={loading} />
      <div className="d-flex py-3 justify-content-between align-items-center border-bottom">
        <div className="w-50">
          <h4 className="text-primary">{interventionLogicType}</h4>
          <h6>{interventionLogicName}</h6>
        </div>
        {/* progress bar */}
        <ProgressBar value={90} />
      </div>

      {/* Table */}
      <div>
        <Table columns={columns} data={items} />
      </div>

      <Modal
        isOpen={state.modalIsOpen}
        onRequestClose={() => toggleFundingDetailModal()}
        ariaHideApp={false}
        overlayClassName="modal-lg smart-indicator-modal"
        contentLabel="Smart Indicator"
      >
        {/* Modal Header */}
        <Icon.XCircle
          onClick={() => toggleFundingDetailModal()}
          className="action-icons mb-2"
        />
        <h4>{interventionLogicName}</h4>

        {/* Modal Body */}
        <div className="row justify-content-between">
          {/* Body left Side */}
          <div className="col-md-6">
            {ilDetailsData?.indicator && (
              <>
                <h5 className="my-2">Indicator</h5>
                <p>{ilDetailsData?.indicator}</p>
              </>
            )}
            {ilDetailsData?.definition && (
              <>
                <h5 className="my-2">Definition</h5>
                <p>{ilDetailsData?.definition}</p>
              </>
            )}
            <h5 className="my-2">Means of Verification</h5>
            <p>{ ilDetailsData?.siMeanOfVerifications[0]?.name}</p>

            <h5 className="my-2">Periodicity</h5>
            <p>{ilDetailsData?.periodicity?.name ?? ""}</p>

            <h5 className="my-2">Intervention Logic</h5>
            <p>{ilDetailsData?.interventionLogic?.name ?? ""}</p>

            <h5 className="my-2">Responsible Person</h5>
            <p>{ilDetailsData?.responsiblePerson?.name ?? ""}</p>
          </div>

          {/* Body left Side */}
          <div className="col-md-6">
            <h5 className="my-2">Indicator Achievement</h5>
            <ProgressBar
              value={getAchievementRate(
                ilDetailsData?.indicatorAchievedPercentage
              )}
            />
            <h5 className="mt-4">Achievements in Number</h5>
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="app-card text-center">
                  <p className="font-weight-bold h3">
                    {ilDetailsData?.indicatorTargetPercentage}
                  </p>
                  <small>Indicator Target</small>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="app-card text-center">
                  <p className="font-weight-bold h3">
                    {ilDetailsData?.totalTarget}
                  </p>
                  <small>Target As Numbers</small>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="app-card text-center">
                  <p className="font-weight-bold h3">
                    {ilDetailsData?.baselineValue}
                  </p>
                  <small>Baseline Value</small>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="app-card text-center">
                  <p className="font-weight-bold h3">
                    {ilDetailsData?.achievedTillDate}
                  </p>
                  <small>Achieved Till Date</small>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="app-card text-center">
                  <p className="font-weight-bold h3">
                    {ilDetailsData?.indicatorValueTillDate}
                  </p>
                  <small>Indicator Till Date</small>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="app-card text-center">
                  <p className="font-weight-bold h3">
                    {ilDetailsData?.achievementRate}
                  </p>
                  <small>Achievement Rate(%)</small>
                </div>
              </div>
            </div>

            {/* Button Area */}
            <div className="mt-5 text-right">
              {/* <button
                type="button"
                className="btn btn-outline-primary mx-1"
              >
                Edit
              </button> */}
              <button
                onClick={() => toggleAchievmentModal()}
                type="button"
                className="btn btn-secondary mx-1"
              >
                Add Achievement
              </button>
              <button
                type="button"
                className="btn btn-primary mx-1"
                onClick={() => toggleFundingDetailModal()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={state.achieveModalIsOpen}
        onRequestClose={() => toggleAchievmentModal()}
        ariaHideApp={false}
        overlayClassName="modal-lg smart-indicator-modal"
        contentLabel="Achievement Details!"
      >
        {/* Modal Header */}
        <Icon.XCircle
          onClick={() => toggleAchievmentModal()}
          className="action-icons"
        />
        <h4>{ilDetailsData?.name}</h4>

        {/* Modal Body */}
        <div className="justify-content-between">
          <div className="row">
            <div className="col-md-6">
              <h5 className="my-2">Indicator</h5>
              <p>{ilDetailsData?.indicator}</p>
            </div>
            <div className="col-md-6">
              <h5 className="my-2">Definition</h5>
              <p>{ilDetailsData?.definition}</p>
            </div>
          </div>
          <div className="my-3">
            <h5 className="my-2">Achieved Till Date</h5>
            <p>
              <b>{ilDetailsData?.achievedTillDate}</b>
            </p>
          </div>
          <Form
            formData={achievementFields}
            onSubmit={(params) => postAchievmentData(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
          {/* Button Area */}
          {/* <div className="mt-5 text-right">
            <button type="button" className="btn btn-secondary mx-1">
              Submit
            </button>
            <button
              type="button"
              className="btn btn-primary mx-1"
              onClick={()=>toggleAchievmentModal()}
            >
              Cancel
            </button>
          </div> */}
        </div>
      </Modal>
    </div>
   </>
  );
};

export default SpecificObjective;
