import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { INTERVENTION_LOGIC_TYPE } from '../../Constants';
import { clearForm, createRecord } from '../../redux/reducers';

import Form from '../form/Form';
import Loader from '../loader/loader';
import { useParams } from 'react-router-dom';

const AddExpectedResult = () => {

    let {id}  = useParams();
    const projectId = id;
    const dispatch = useDispatch();
    const { formFields } = useSelector((state) => state.fields);
    const { created, loading } = useSelector((state) => state.records);
    const { expectedResults = {} } = useSelector((state) => state.records?.current);
    console.log("🚀 ~ AddExpectedResult ~ expectedResults:", expectedResults)


    //by default clear previous step form data
    useEffect(() => {
        dispatch({ type: clearForm.type })
    }, [])
    
    useEffect(() => {
        getExpectedResults();
    }, [created])

    function getExpectedResults() {
        dispatch({
            type: "records/getRecords",
            payload: {
                url: `/InterventionLogic/GetExpectedResults?ProjectId=${projectId}`,
                appendCurrentReducerKey: "expectedResults",
            },
        });
    }



    const onAdd = (params) => {
        let formData = { ...params, interventionLogicType: { value: INTERVENTION_LOGIC_TYPE.EXPECTED_RESULT }, projectId: { value: projectId } }
        dispatch({ type: createRecord.type, payload: { ...formData, url: '/InterventionLogic' } })
    };

    function getFieldsByCategory(fields = [], key) {
        return fields.filter(({ category = '' }) => category == key)
    }

    function formatFields() {
        let fields = getFieldsByCategory(formFields, 'logframe')
        return fields.map((field) => {
            if (field.name == 'specificObjective')
                return { ...field, url: field.url + projectId }
            return field
        })
    }

    return (
        <div className="position-relative">
            {/* <Loader loading={loading} theme="light" /> */}
            <Form
                formData={formatFields()}
                onSubmit={(params) => onAdd(params)}
                withValidation={false}
                formValidation={[]}
                extraInputClass=""
            />
             <hr />
            <div>
                <div className='font-weight-bold'>Specific Objectives List</div>
                <div className="">
                    <div className="row">
                        {
                         expectedResults?.data?.items.length > 0 ?   (expectedResults?.data?.items ?? []).map((item, index) => (
                                <div className="col-md-4" key={index}>
                                    <div className="border p-2 mb-2 mt-2  text-center">
                                        {item?.interventionLogicName}
                                    </div>
                                </div>
                            ))

                            : <div className='p-3'>No Specific Objectives Found</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddExpectedResult;