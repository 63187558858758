import React, { useEffect } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import SimplePieChart from "../../chart/SimplePieChart";
import Label from "../../ui/Label";
import "./OverlappingBeneficiaries.scss";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../../redux/reducers";

const OverlappingBeneficiaries = () => {
  const dispatch = useDispatch();
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const { OverlappingBeneficiaries = { data: { items: [{ totalBeneficiaries: 0, overlappingBeneficiaries: 0 }] } } } = useSelector((state) => state.records.current || {});

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetOverlappingBeneficiaries`,
        appendCurrentReducerKey: "OverlappingBeneficiaries",
      },
    });
  }, [dispatch]);

  return (
    <div className="bg-white shadow-sm h-100 fundsDonarDistribution">
      <Label>Overlapping Beneficiaries</Label>
      <div className="d-flex justify-content-center">
        <SimplePieChart
          type="donut"
          width={isMobileView ? 160 : 300}
          height={isMobileView ? 240 : 300}
          series={[
            OverlappingBeneficiaries.data.items[0]?.totalBeneficiaries || 0,
            OverlappingBeneficiaries.data.items[0]?.overlappingBeneficiaries || 0
          ]}
          labels={["Total Beneficiaries", "Overlapping Beneficiaries"]}
          position={"bottom"}
          colors={["#554B86", "#0E7BB6"]}
          legendOptions={{
            show: true,
          }}
        />
      </div>
    </div>
  );
};

export default OverlappingBeneficiaries;