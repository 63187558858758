import React from "react";
import * as Icons from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";

const ListHeader = ({ children, options = [], ...otherProps }) => {
  return (
    <div className="d-flex">
      {children}
      {options.map(({ url, id, name, label = "Add", text = "", itemClassName="", iconColor="#ccc" }) => {
        const { [name]: Icon } = Icons;
        return (
          <ToolTip label={label} key={id}>
            <Link to={url} key={id} className={itemClassName} >
              <Icon size={20} color={iconColor} className="action-icons " />
              {text && <span>{text}</span>}
            </Link>
          </ToolTip>
        );
      })}
    </div>
  );
};

export default ListHeader;
