import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import Table from "../../components/table/Table";
import usePageMeta from "../../hooks/usePageMeta";
import * as Icon from "react-bootstrap-icons";

import { createRecord, getRecords } from "../../redux/reducers";
import NotificationColumns from "../../assets/columns/NotificationColumns";
import ToolTip from "../../components/tooltip/Tooltip";
import { ORDER_DIRECTION } from "../../Constants";

const NotificationPage = () => {
  const { setMeta } = usePageMeta();
  const dispatch = useDispatch();
  const { loading, data, created } = useSelector((state) => state.records);
  const { items = [] } = data;

  const columns = [
    ...NotificationColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          {row.isRead ? (
            <Icon.EnvelopeOpen />
          ) : (
            <ToolTip label="Mark as read">
              <Icon.EnvelopeFill
                className="text-primary mr-1 cursor-pointer"
                onClick={() => markAsRead(row)}
              />
            </ToolTip>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (created)
      getNotifications();
  }, [created]);

  const markAsRead = ({ id }) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/Notification/MarkAsRead?id=${id}`,
      },
    });
  };

  useEffect(() => {
    setMeta({ title: "Notifications" });
    getNotifications();
  }, []);

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/GetAll`,
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
      },
    });
  }

  return (
    <div className="my-3">
      <Loader loading={loading} />
      <Table columns={columns} data={items} />
    </div>
  );
};

export default NotificationPage;
