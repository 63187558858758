import React from "react";

const SimpleCard = ({title, subTitle,children}) => {
  return (
    <div className="card mt-2 p-1">
      <div className="card-body">
        {title && <h5 className="card-title">{title}</h5>}
        {subTitle && <h6 className="card-subtitle mb-2 text-muted">{subTitle}</h6>}
        <div className="card-text">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SimpleCard;
