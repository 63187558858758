import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { complaintAppealFields } from "../../assets/fields/complaintAppealFields";

import { clearForm, putRecord } from "../../redux/reducers";
import Form from "../form/Form";
import Loader from "../loader/loader";

const ComplaintAppealModalx = ({
  modalIsOpen,
  selectedResponse,
  toggleComplaintAppealModal,
}) => {
  const dispatch = useDispatch();
  const { loading, created, data } = useSelector((state) => state.records);

  useEffect(() => {
    if (created) { }
    toggleComplaintAppealModal()
    return () => {
    }
  }, [created])

  useEffect(() => {
    if (!modalIsOpen) {
      dispatch({ type: clearForm.type })
    }
  }, [modalIsOpen])

  function postData(params) {
    const formValues = { ...params, responseId: { value: selectedResponse?.id } }
    dispatch({ type: putRecord.type, payload: { ...formValues, url: '/ComplaintsResponse/SetAppeal' } })
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
      >
        {modalIsOpen && <div className="mb-5">
          <Icon.XCircle onClick={toggleComplaintAppealModal} className="action-icons mb-2" />
          <h4 className="text-capitalize">{data.complainantName}: Complaint Feedback</h4>
          <Loader loading={loading} />
          <Form
            formData={complaintAppealFields ? [...complaintAppealFields] : []}
            onSubmit={(params) => postData(params)}
            withValidation={false}
            formValidation={[]}
          />
        </div>}
      </Modal>
    </>
  );
};

export default ComplaintAppealModalx;
